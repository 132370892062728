// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDGdJuEGkPoTOaVFO8PBKJi-4jhFdMf20g',
  authDomain: 'menuvous.firebaseapp.com',
  projectId: 'menuvous',
  storageBucket: 'menuvous.appspot.com',
  messagingSenderId: '805113179906',
  appId: '1:805113179906:web:1f3c6f2e2a7e85ae1e9c1b',
  measurementId: 'G-9S84TBEPVL',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize providers
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

const firestore = getFirestore(app);

export { firestore };
export { auth, googleProvider, appleProvider };
