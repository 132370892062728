import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';  // Import useNavigate instead of useHistory
import MenuDisplay from './MenuDisplay';
import { getMenuById } from '../api';
import { getUserProfile } from '../api';

import { addToBasket, removeFromBasket, updateBasketItemQuantity, getBasketItemCount } from '../utils/basketUtils';
import Header from './Header';
import BasketModal from './BasketModal';
import { auth } from '../firebase'; // Make sure to import auth from your firebase config
import '../styles/App.css';

function LoadedMenuPage() {
  const user = auth.currentUser;
  const { menuId } = useParams();
  const navigate = useNavigate();
  const [menuData, setMenuData] = useState([]);
  const [menuUploadDate, setMenuUploadDate] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(true);
  const [basketItems, setBasketItems] = useState([]);
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [error, setError] = useState(null);
  const [restaurant, setRestaurant] = useState({ name: '', streetAddress: '' });
  const [profile, setProfile] = useState(null);
    
  const fetchUserProfile = useCallback(async () => {
    try {
      const userProfile = await getUserProfile();
      setProfile(userProfile);
      console.log(userProfile);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  }, [setProfile]);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
      
    }
  }, [user, fetchUserProfile]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await getMenuById(menuId);
        if (response.success && response.menu) {
          const fetchedMenuData = Array.isArray(response.menu.menuData)
            ? response.menu.menuData
            : [response.menu.menuData];

          const images = (response.menu.images || []).map((image) => {
            let url = '';
            if (image.data) {
              url = `data:${image.contentType};base64,${image.data}`;
            } else if (image.url) {
              url = image.url;
            }
            return {
              url,
              loading: false,
              error: false,
            };
          });

          setMenuData(fetchedMenuData);
          setMenuUploadDate(response.menu.createdAt);
          setUploadedImages(images);
          setCurrency(response.menu.currency || '');
          try {
            setRestaurant({
              name: response.menu.restaurant.name,
              streetAddress: response.menu.restaurant.streetAddress,
            });
          } catch {
            console.error('Error setting restaurant information:', response.menu.restaurant);
            setRestaurant({
              name: "Unknown venue",
              streetAddress: "",
            });
          } finally {
            //console.log("Continuing");
          }
          
        } else {
          setError('Failed to load menu.');
        }
      } catch (err) {
        console.error('Error fetching menu:', err);
        setError('An error occurred while fetching the menu.');
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
  }, [menuId]);

  const handleAddToBasket = (itemId, itemData) => {
    setBasketItems((prevItems) => addToBasket(prevItems, itemId, itemData));
  };

  const handleRemoveFromBasket = (itemId) => {
    setBasketItems((prevItems) => removeFromBasket(prevItems, itemId));
  };

  const handleUpdateBasketItemQuantity = (itemId, quantity) => {
    setBasketItems((prevItems) => updateBasketItemQuantity(prevItems, itemId, quantity));
  };

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-content">
          <h2 className="loading-title">Please wait...</h2>
          <div className="loading-spinner"></div>
          <h2 className="loading-subtitle">Loading your menu</h2>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="menu-page">
      <Header
        onBasketClick={() => setIsBasketOpen(true)}
        basketItemCount={getBasketItemCount(basketItems)}
        onLogoClick={() => navigate('/')} // Use navigate instead of history
        user={user}
        profile={profile}
        setProfile={setProfile}
      />
      <div className="menu-container">
        <MenuDisplay
          menuData={menuData}
          uploadedImages={uploadedImages}
          currency={currency}
          readOnly={true}
          menuUploadDate={menuUploadDate}
          menuId={menuId}
          addToBasket={handleAddToBasket}
          removeFromBasket={handleRemoveFromBasket}
          basketItems={basketItems}
          restaurant={restaurant}
          profile={profile}
          setProfile={setProfile}
        />
      </div>
      {isBasketOpen && (
        <BasketModal
          basketItems={basketItems}
          closeModal={() => setIsBasketOpen(false)}
          addToBasket={handleAddToBasket}
          removeFromBasket={handleRemoveFromBasket}
          updateBasketItemQuantity={handleUpdateBasketItemQuantity}
          menuData={menuData}
          currency={currency}
        />
      )}
    </div>
  );
}

export default LoadedMenuPage;
