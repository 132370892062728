// components/BasketModal.js

import React, { useEffect, useCallback } from 'react';
import '../styles/BasketModal.css';

function BasketModal({
  basketItems,
  closeModal,
  addToBasket,
  removeFromBasket,
  updateBasketItemQuantity,
  menuData,
  currency,
}) {

  const handleClose = useCallback(() => {
    closeModal();
    // Remove the state we added when opening the modal
    window.history.back();
  }, [closeModal]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    const handlePopState = (event) => {
      event.preventDefault();
      handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    window.addEventListener('popstate', handlePopState);

    // Push a new state to the history when opening the modal
    window.history.pushState(null, '');

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', handleEscape);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleClose]);
  
  // Function to group basket items by course
  const groupItemsByCourse = () => {
    const courseMap = {};

    basketItems.forEach((item) => {
      const course = findCourseByItemId(item.id);
      if (course) {
        if (!courseMap[course.o]) {
          courseMap[course.o] = [];
        }
        courseMap[course.o].push(item);
      }
    });

    return courseMap;
  };

  // Helper function to find the course of an item by item ID
  const findCourseByItemId = (itemId) => {
    // Check if menuData is an array (multiple menus) or a single menu object
    const menus = Array.isArray(menuData) ? menuData : [menuData];
  
    for (const menu of menus) {
      if (menu.courses && Array.isArray(menu.courses)) {
        for (const course of menu.courses) {
          if (course.i && Array.isArray(course.i)) {
            for (const item of course.i) {
              // Check variants
              if (typeof item.p === 'object') {
                for (const variant of Object.values(item.p)) {
                  if (variant && variant.name) {
                    const itemUniqueId = `${item.e} - ${variant.name}`;
                    if (itemUniqueId === itemId) {
                      return course;
                    }
                  }
                }
              } else {
                const itemUniqueId = item.e;
                if (itemUniqueId === itemId) {
                  return course;
                }
              }
            }
          }
        }
      }
    }
    return null;
  };

  // Calculate total price and total items
  const totalPrice = basketItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const totalItems = basketItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const groupedItems = groupItemsByCourse();

  return (
    <div
      className="basket-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <div className="basket-modal">
        <button className="close-button" onClick={closeModal}>
          &#10005;
        </button>
        <h2 className="modal-heading">YOUR ORDER</h2>
        {Object.keys(groupedItems).length === 0 ? (
          <p>Basket is empty.</p>
        ) : (
          <div className="basket-content">
            <div className="basket-summary">
              <span>{totalItems} items</span>
              <span>
                Total: <strong>{currency}{totalPrice.toFixed(2)}</strong>
              </span>
            </div>
            {Object.entries(groupedItems).map(([courseName, items], idx) => {
              // Calculate subtotal for this course
              const courseSubtotal = items.reduce(
                (subtotal, item) => subtotal + item.price * item.quantity,
                0
              );
              return (
                <div key={idx} className="basket-course-section">
                  <h3 className="course-name">{courseName.toUpperCase()}</h3>
                  <div className="basket-items-list">
                    {items.map((item, index) => (
                      <div key={index} className="basket-item">
                        <div className="item-details">
                          <div className="item-names">
                            {/* Original name in italics */}
                            <p className="original-name">{item.originalName}</p>
                            {/* English translation indented */}
                            <p className="translated-name">{item.name}</p>
                            {item.variant && <p>{item.variant}</p>}
                          </div>
                          <div className="action-and-price-line">
                            <div className="action-buttons">
                              <span
                                className="action-text fewer-button"
                                onClick={() => removeFromBasket(item.id)}
                              >
                                Fewer
                              </span>
                              <span
                                className="action-text more-button"
                                onClick={() => addToBasket(item.id, item)}
                              >
                                More
                              </span>
                            </div>
                            <div className="price-and-total">
                              <span className="item-price">
                                {currency}
                                {item.price.toFixed(2)}
                              </span>
                              <span className="total-price">
                                {currency}
                                {(item.price * item.quantity).toFixed(2)} ({item.quantity})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Subtotal for this course */}
                  <div className="course-subtotal">
                    Subtotal: <strong>{currency}{courseSubtotal.toFixed(2)}</strong>
                  </div>
                </div>
              );
            })}
            <div className="basket-summary bottom-summary">
              <span>{totalItems} items</span>
              <span>
                Total: <strong>{currency}{totalPrice.toFixed(2)}</strong>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BasketModal;
