import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';
import './styles/App.css';
import FileUploader from './components/FileUploader'
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import MenuDisplay from './components/MenuDisplay';
import BasketModal from './components/BasketModal';
import Header from './components/Header';
import MenuPage from './components/LoadedMenuPage';
import { addToBasket, removeFromBasket, updateBasketItemQuantity, getBasketItemCount } from './utils/basketUtils';
import MessageModal from './components/MessageModal';
import NotFound from './components/NotFound'; 
import DiscoverPage from './components/Discover';

function App() {
  const [step, setStep] = useState('home');
  const [menuData, setMenuData] = useState([]);
  const [basketItems, setBasketItems] = useState([]);
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState('');
  //const [pendingWineRequests, setPendingWineRequests] = useState({});
  //const hasLoggedCompletion = useRef(false);
  //const fileInputRef = useRef(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const fileUploaderRef = useRef(null);
  const [winePairingsFetched, setWinePairingsFetched] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [profile, setProfile] = useState({
    points: 0,
    dietaryPreferences: {
      allergies: [],
      'gluten-free': false,
      pescatarian: false,
      vegan: false,
      vegetarian: false,
    },
    cuisines: [],
    diningInterests: [],
    wineKnowledgeLevel: "I don't care",
  });

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });
  
    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  const handleGoHome = () => {
    setStep('home');
    setMenuData([]);
    setUploadedImages([]);
    setBasketItems([]);

  };

  const handleRemoveImage = (index) => {  
    // Use functional updates to ensure we are working with the latest state
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setMenuData((prevData) => {
      const updatedMenu = prevData.filter((_, i) => i !== index);
      return updatedMenu;
    });
  };
  

  const handleAddToBasket = (itemId, itemData) => {
    setBasketItems((prevItems) => addToBasket(prevItems, itemId, itemData));
  };

  const handleRemoveFromBasket = (itemId) => {
    setBasketItems((prevItems) => removeFromBasket(prevItems, itemId));
  };

  const handleUpdateBasketItemQuantity = (itemId, quantity) => {
    setBasketItems((prevItems) => updateBasketItemQuantity(prevItems, itemId, quantity));
  };

  const handleMenuDataUpdate = (newMenuData) => {
    setMenuData(newMenuData);
  };

  const handleStepUpdate = (newStep) => {
    setStep(newStep);
  };


  const handleScanMenu = () => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.openFileDialog();
    } else {
      console.error('FileUploader ref is not available');
    }
  };

  //useEffect(() => {
  //  console.log('MenuDisplay received new menuData:', menuData);
  //}, [menuData]);

  return (
    <Router>
      <div className="App">
        {warning && (
          <MessageModal
            title="Warning"
            message={warning}
            onClose={() => setWarning('')}
          />
        )}
        {error && (
          <MessageModal
            title="Error"
            message={error}
            onClose={() => setError('')}
          />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {step !== 'home' && (
                  <Header
                    onBasketClick={() => setIsBasketOpen(true)}
                    basketItemCount={getBasketItemCount(basketItems)}
                    onLogoClick={handleGoHome}
                    user={user}
                    profile={profile}
                    setProfile={setProfile}
                  />
                )}
                {step === 'home' && (
                  <HomePage
                    handleScanMenu={handleScanMenu}
                    user={user}
                    setUser={setUser}
                    profile={profile}
                    setProfile={setProfile}
                    setStep={setStep}
                    step={step}
                  />
                )}
                {step === 'home' && loading && (
                  <div className="loading-overlay">
                    <div className="loading-content">
                      <h2 className="loading-title"> </h2>
                      <div className="loader"></div>
                      <h2 className="loading-subtitle"> </h2>
                    </div>
                  </div>
                )}
                {step === 'menu' && (
                  <div className="menu-container">
                    <MenuDisplay
                      menuData={menuData}
                      addToBasket={handleAddToBasket}
                      removeFromBasket={handleRemoveFromBasket}
                      basketItems={basketItems}
                      uploadedImages={uploadedImages}
                      currency={currency}
                      handleAddImage={handleScanMenu}
                      handleRemoveImage={handleRemoveImage}
                      //pendingWineRequests={pendingWineRequests}
                      isLoadingNewMenu={loading}
                      winePairingsFetched={winePairingsFetched}
                      currentUser={user}
                      setCurrentUser={setUser}
                      profile={profile}
                      setProfile={setProfile}
                    />
                    {isBasketOpen && (
                      <BasketModal
                        basketItems={basketItems}
                        closeModal={() => setIsBasketOpen(false)}
                        addToBasket={handleAddToBasket}
                        removeFromBasket={handleRemoveFromBasket}
                        updateBasketItemQuantity={handleUpdateBasketItemQuantity}
                        menuData={menuData}
                        currency={currency}
                      />
                    )}
                  </div>
                )}
                {step === 'discover' && (
                  <div className="menu-container">
                    <DiscoverPage
                    />
                  </div>
                )}
                <Footer />
              </>
            }
          />
          <Route
            path="/menu/:menuId"
            element={<MenuPage />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <FileUploader
          winePairingsFetched={winePairingsFetched}
          setWinePairingsFetched={setWinePairingsFetched}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          onMenuDataUpdate={handleMenuDataUpdate}
          onStepUpdate={handleStepUpdate}
          setLoading={setLoading}
          loading={loading}
          ref={fileUploaderRef}
          setCurrency={setCurrency}
          step={step}
          setStep={setStep}
        />
      </div>
    </Router>
  );
}

export default App;
