// components/PriceSelectionModal.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import '../styles/PriceSelectionModal.css';

function PriceSelectionModal({
  item,
  closeModal,
  addToBasket,
  removeFromBasket,
  basketItems,
  currency,
}) {
  const [selectedVariants, setSelectedVariants] = useState({});

  // Memoize validVariants calculation
  const validVariants = useMemo(() => {
    return Object.entries(item.p).reduce((acc, [key, value]) => {
      if (value && value.name !== "" && value.price > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [item.p]);

  // Memoize handleAddVariant function
  const handleAddVariant = useCallback((variantKey) => {
    const variantName = validVariants[variantKey].name;
    const itemId = `${item.e} - ${variantName}`;
    addToBasket(itemId, {
      id: itemId,
      name: item.e,
      originalName: item.o,
      price: validVariants[variantKey].price,
      variant: variantName,
    });
    setSelectedVariants((prev) => ({
      ...prev,
      [variantKey]: (prev[variantKey] || 0) + 1,
    }));
  }, [validVariants, item.e, item.o, addToBasket]);

  // Memoize handleRemoveVariant function
  const handleRemoveVariant = useCallback((variantKey) => {
    const variantName = validVariants[variantKey].name;
    const itemId = `${item.e} - ${variantName}`;
    removeFromBasket(itemId);
    setSelectedVariants((prev) => ({
      ...prev,
      [variantKey]: Math.max((prev[variantKey] || 0) - 1, 0),
    }));
  }, [validVariants, item.e, removeFromBasket]);

  useEffect(() => {
    const initialVariants = {};
    Object.keys(validVariants).forEach((variantKey) => {
      const itemId = `${item.e} - ${validVariants[variantKey].name}`;
      const basketItem = basketItems.find((bi) => bi.id === itemId);
      initialVariants[variantKey] = basketItem ? basketItem.quantity : 0;
    });
    setSelectedVariants(initialVariants);
  }, [item.e, basketItems, validVariants]);

  // Memoize totalItems and totalPrice calculations
  const { totalItems, totalPrice } = useMemo(() => {
    const items = Object.values(selectedVariants).reduce((sum, qty) => sum + qty, 0);
    const price = Object.entries(selectedVariants).reduce(
      (sum, [variantKey, qty]) => sum + validVariants[variantKey].price * qty,
      0
    );
    return { totalItems: items, totalPrice: price };
  }, [selectedVariants, validVariants]);

  // If there's only one valid variant, don't render the modal
  if (Object.keys(validVariants).length <= 1) {
    return null;
  }

  return (
    <div className="price-selection-modal-overlay" onClick={closeModal}>
      <div className="price-selection-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={closeModal}>
          &#10005;
        </button>
        <h2 className="modal-heading">SELECT OPTION</h2>
        <h3 className="item-name">{item.e}</h3>
        <div className="item-original-name">{item.o}</div>
        <div className="variants-list">
          {Object.entries(validVariants).map(([variantKey, variantInfo]) => (
            <div key={variantKey} className="variant-item">
              <div className="variant-line">
                <span className="variant-name">{variantInfo.name}</span>
                <span className="item-price">
                  {currency}{variantInfo.price.toFixed(2)}
                </span>
              </div>
              <div className="action-and-price-line">
                <div className="action-buttons">
                  {selectedVariants[variantKey] === 0 ? (
                    <span
                      className="action-text add-button"
                      onClick={() => handleAddVariant(variantKey)}
                    >
                      Add
                    </span>
                  ) : (
                    <>
                      <span
                        className="action-text fewer-button"
                        onClick={() => handleRemoveVariant(variantKey)}
                      >
                        Fewer
                      </span>
                      <span
                        className="action-text more-button"
                        onClick={() => handleAddVariant(variantKey)}
                      >
                        More
                      </span>
                    </>
                  )}
                </div>
                <div className="price-and-total">
                  {selectedVariants[variantKey] > 0 && (
                    <span className="total-price">
                      {currency}{(variantInfo.price * selectedVariants[variantKey]).toFixed(2)} ({selectedVariants[variantKey]})
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className="add-to-order-button"
          onClick={closeModal}
          disabled={totalItems === 0}
        >
          Add to order ({totalItems}) {currency}{totalPrice.toFixed(2)}
        </button>
      </div>
    </div>
  );
}

export default PriceSelectionModal;