// components/MenuDisplay.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { submitMenuQuery, saveMenuToDatabase } from '../api';
import { fetchNearbyVenues } from '../api';
import MenuItemCard from './MenuItemCard';
import QRCodeModal from './QRCodeModal';
import VenueSelector from './VenueSelector';
import ItemDescriptionModal from './ItemDescriptionModal';
import FilterButtons from './FilterButtons';
import MessageModal from './MessageModal';
import "../styles/MenuDisplay.css";
import { getUserProfile } from '../api';
import { QRCodeCanvas } from 'qrcode.react';
import { auth } from '../firebase';
import sadDog from '../images/sad-dog.png';
import AdvicePopup from './AdvicePopup';

function MenuDisplay({
  menuData = [],
  addToBasket = () => { },
  removeFromBasket = () => { },
  basketItems = [],
  uploadedImages = [],
  currency,
  setCurrency,
  handleAddImage = null,
  handleRemoveImage = null,
  handleRetryUpload = null,
  isLoadingNewMenu = false,
  isWinePairingsLoading,
  readOnly = false,
  menuUploadDate = null,
  menuId = null,
  disableDescriptionModal = false,
  restaurant = {},
  winePairingsFetched,
  currentUser,
  setCurrentUser,
  profile,
  setProfile,
}) {
  const user = auth.currentUser;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');
  const [qaList, setQaList] = useState([]);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [shareType, setShareType] = useState('menu');
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [isVenueSelectorOpen, setIsVenueSelectorOpen] = useState(false);
  const [nearbyVenues, setNearbyVenues] = useState([]);
  const [error, setError] = useState('');
  const [userExplicitlyNotAtVenue, setUserExplicitlyNotAtVenue] = useState(false);
  const [savedMenuId, setSavedMenuId] = useState(null);

  const [activeFilter, setActiveFilter] = useState(null);

  const [fadeIn, setFadeIn] = useState(true);

  const [tutorialStep, setTutorialStep] = useState(-1);
  const menuNameRef = useRef(null);
  const questionInputRef = useRef(null);
  const filterButtonRef = useRef(null);
  const menuPreviewRef = useRef(null);
  const courseNameTitleRef = useRef(null);
  const itemCardRef = useRef(null);
  const plusButtonRef = useRef(null);

  const firstCourseNameRef = useRef(null);
  const firstItemCardRef = useRef(null);

  useEffect(() => {
    // Assuming menuData has loaded or is not empty
    if (menuData.length > 0) {
      // Set the reference for the first course name and item
      const firstCourse = document.querySelector('.course-name');
      if (firstCourse) firstCourseNameRef.current = firstCourse;
      const firstMenuItemCard = document.querySelector('.menu-item-card');
      if (firstMenuItemCard) firstItemCardRef.current = firstMenuItemCard;
    }
  }, [menuData]);

  const tutorialSteps = [
    {
      text: readOnly ? "Welcome to Menu Vous! This page shows a menu that has been scanned by another user.<br />Let's take a look." : "Welcome to your first Menu Vous menu! Let's take a look.<br /><br />Please note that it's still early days for Menu Vous - make sure to check all responses and info with staff.",
      targetRef: null,
    },
    {
      text: readOnly ? "Here's the name of the venue." : "Here's the name of your closest venue. <br /><br />You can change this if it's not correct.",
      targetRef: menuNameRef,
    },
    {
      text: "You can ask any question about the menu here.<br />We'll do our best to answer it.<br /><br />Please ensure to check results with staff. They know the menu best!",
      targetRef: questionInputRef,
    },
    {
      text: "If your menu contains different categories of items, the buttons here allow you to filter them.",
      targetRef: filterButtonRef,
    },
    {
      text: readOnly ? "Press on the image previews to expand the images of the menu." : "Press on the image previews to expand the images of the menu. You can also add new images to add to your menu.",
      targetRef: menuPreviewRef,
    },
    {
      text: "If the original menu is split into courses, each of them should be shown here.",
      targetRef: firstCourseNameRef,
    },
    {
      text: "Tap on an item to see more information about it.<br /><br />🍽️ Food items that have unusual names or ingredients have additional information available.<br /><br />🍷 Wines have a lot more information, including tasting notes and pairing suggestions.",
      targetRef: firstItemCardRef,
    },
    {
      text: "We hope you enjoy Menu Vous! If you do, please tell your friends and consider donating to support our mission.<br /><br />If you have any questions or feedback, see the bottom of the page for contact info and FAQs",
      targetRef: plusButtonRef,
    },
  ];

  const handlePreviousTutorialStep = () => {
    if (tutorialStep > 0) {
      setTutorialStep(tutorialStep - 1);
    }
  };

  const handleNextTutorialStep = () => {
    if (tutorialStep < tutorialSteps.length - 1) {
      setTutorialStep(tutorialStep + 1);
    } else {
      handleCloseTutorial();
    }
  };

  const handleCloseTutorial = () => {
    setTutorialStep(-1);
  };

  const handleFinishTutorial = () => {
    setTutorialStep(-1);
    localStorage.setItem('hasCompletedMenuTutorial', 'true');
  };

  useEffect(() => {
    const hasCompletedMenuTutorial = localStorage.getItem('hasCompletedMenuTutorial');
    if (!hasCompletedMenuTutorial) {
      setTutorialStep(0);
    }
  }, []);

  const fetchUserProfile = useCallback(async () => {
    try {
      const userProfile = await getUserProfile();
      setProfile(userProfile);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  }, [setProfile]);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user, fetchUserProfile]);

  const saveMenuToDatabaseFunction = useCallback(
    async (menuData, selectedVenue) => {
      try {
        setCurrentUser(auth.currentUser);
        if (!currentUser) {
          // Handle unauthenticated user
        }

        const restaurantUid = selectedVenue?.id || null;
        const restaurantName = selectedVenue?.displayName?.text || null;
        const streetAddress = selectedVenue?.formattedAddress || null;

        const imageFiles = uploadedImages.map((image) => image.file).filter(Boolean);

        const response = await saveMenuToDatabase(
          restaurantUid,
          restaurantName,
          streetAddress,
          menuData,
          imageFiles,
          currentUser?.uid || "none"
        );

        if (response.success) {
          return response.menuId;
        } else {
          throw new Error('Failed to save menu');
        }
      } catch (error) {
        console.error('Error saving menu:', error);
        setError('Error saving menu: ' + error.message);
        throw error;
      }
    },
    [uploadedImages, currentUser, setCurrentUser]
  );

  const handleLocationAccess = useCallback(() => {
    if (!readOnly) {
      if (!selectedVenue && !userExplicitlyNotAtVenue) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              setUserLocation({ latitude, longitude });

              try {
                const nearbyVenuesResponse = await fetchNearbyVenues(latitude, longitude, 200, 5);
                setNearbyVenues(nearbyVenuesResponse);
                if (nearbyVenuesResponse.length > 0) {
                  setSelectedVenue(nearbyVenuesResponse[0]);
                } else {
                  setSelectedVenue(null);
                }
              } catch (error) {
                console.error("Error fetching nearby venues:", error);
                setError(`Error fetching nearby venues: ${error.message} ${error}`);
              }
            },
            (error) => {
              console.error("Error getting location:", error);
              setError(`Error getting location: ${error.message}`);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          setError("Geolocation is not supported by this browser.");
        }
      }
    }
  }, [selectedVenue, userExplicitlyNotAtVenue, setUserLocation, setNearbyVenues, setSelectedVenue, setError, readOnly]);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          handleLocationAccess();
        }
      });
    }
  }, [handleLocationAccess]);

  const combineMenus = (menuData) => {
    let combinedMenu = {
      name: 'Combined Menu',
      courses: [],
    };

    menuData.forEach((menu) => {
      if (menu.courses && menu.courses.length > 0) {
        combinedMenu.courses = combinedMenu.courses.concat(menu.courses);
      }
    });

    return combinedMenu;
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim()) return;

    setIsLoading(true);

    try {
      const combinedMenuData = combineMenus(menuData);
      const response = await submitMenuQuery(combinedMenuData, question);

      setQaList((prevQaList) => [
        ...prevQaList,
        {
          question: question,
          answer: response.content,
          isExpanded: true,
          id: Date.now(),
        },
      ]);
    } catch (error) {
      console.error('Error submitting question:', error);
      setError('Error submitting question: ' + error.message);
      setQaList((prevQaList) => [
        ...prevQaList,
        {
          question: question,
          answer:
            '<p>Sorry, there was an error processing your question. Please try again.</p>',
          isExpanded: true,
          id: Date.now(),
        },
      ]);
    } finally {
      setIsLoading(false);
      setQuestion('');
    }
  };

  const toggleAnswerExpansion = (id) => {
    setQaList((prevQaList) =>
      prevQaList.map((qa) =>
        qa.id === id ? { ...qa, isExpanded: !qa.isExpanded } : qa
      )
    );
  };

  const removeAnswer = (id) => {
    setQaList((prevQaList) => prevQaList.filter((qa) => qa.id !== id));
  };

  const openImageModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setModalImageSrc('');
  };

  const handleShareClick = useCallback(
    async () => {
      setIsShareLoading(true);
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          setError("Please log in to share menu!");
          //return;
        }

        // Save the menu and venue information to the database
        const menuId = await saveMenuToDatabaseFunction(menuData, selectedVenue);

        // Generate the URL using the returned menuId
        const url = `${window.location.origin}/menu/${menuId}`;
        setQrCodeUrl(url);
        setIsQRCodeModalOpen(true);
      } catch (error) {
        console.error('Error generating QR code:', error);
        setError('Error generating QR code: ' + error.message);
      } finally {
        setIsShareLoading(false);
      }
    },
    [menuData, selectedVenue, saveMenuToDatabaseFunction]
  );

  const isFirstItem = (currentItem) => {
    for (const menu of menuData) {
      for (const course of menu.courses) {
        for (const [index, item] of course.i.entries()) {
          if (item.uid === currentItem.uid) {
            return index === 0 && menu === menuData[0] && course === menuData[0].courses[0];
          }
        }
      }
    }
    return false;
  };

  const isLastItem = (currentItem) => {
    for (const menu of menuData) {
      for (const course of menu.courses) {
        for (const [index, item] of course.i.entries()) {
          if (item.uid === currentItem.uid) {
            let isLastInCourse = index === course.i.length - 1;
            let isLastInMenu = menu === menuData[menuData.length - 1];
            let isLastCourse = course === menu.courses[menu.courses.length - 1];
            return isLastInCourse && isLastInMenu && isLastCourse;
          }
        }
      }
    }
    return false;
  };

  const getItemByUid = (uid) => {
    for (const menu of menuData) {
      for (const course of menu.courses) {
        for (const item of course.i) {
          if (item.uid === uid) {
            return { item, courseName: course.e };
          }
        }
      }
    }
    return null;
  };

  const getNextItem = (currentItem) => {
    let foundCurrent = false;
    for (const menu of menuData) {
      for (const course of menu.courses) {
        for (const item of course.i) {
          if (foundCurrent) {
            return { ...item, courseName: course.e };
          }
          if (item.uid === currentItem.uid) {
            foundCurrent = true;
          }
        }
      }
    }
    return getItemByUid(menuData[0].courses[0].i[0].uid);
  };

  const getPreviousItem = (currentItem) => {
    let prevItem = null;
    for (const menu of menuData) {
      for (const course of menu.courses) {
        for (const item of course.i) {
          if (item.uid === currentItem.uid) {
            return prevItem ? prevItem : getItemByUid(menuData[menuData.length - 1].courses.flatMap(c => c.i).slice(-1)[0].uid);
          }
          prevItem = { ...item, courseName: course.e };
        }
      }
    }
    return null;
  };

  const openDescriptionModal = (item) => {
    if (!disableDescriptionModal) {
      setSelectedItem(item);
      setIsDescriptionModalOpen(true);
    }
  };

  const closeDescriptionModal = () => {
    setIsDescriptionModalOpen(false);
    setSelectedItem(null);
  };

  const BlankCard = () => (
    <div className="blank-card">
      <div className="pulse"></div>
    </div>
  );

  const handleVenueSelect = async (venue) => {
    if (venue && venue.notAtVenue) {
      setSelectedVenue(null);
      setUserExplicitlyNotAtVenue(true);
    } else {
      setSelectedVenue(venue);
      setUserExplicitlyNotAtVenue(false);

      if (venue) {
        try {
          const newMenuId = await saveMenuToDatabaseFunction(menuData, venue);
          setSavedMenuId(newMenuId);
          console.log("Menu ID: ", savedMenuId);
        } catch (error) {
          console.error("Error saving menu after venue selection:", error);
        }
      }
    }
    setIsVenueSelectorOpen(false);
  };

  const computeAvailableFilters = (menuData) => {
    const filters = {
      is_wine: false,
      is_food: false,
      is_drink: false,
      is_afters: false,
    };

    menuData.forEach((menu) => {
      menu?.courses?.forEach((course) => {
        course?.i?.forEach((item) => {
          if (item.is_wine) filters.is_wine = true;
          if (item.is_food) filters.is_food = true;
          if (item.is_drink) filters.is_drink = true;
          if (item.is_afters) filters.is_afters = true;
        });
      });
    });

    return filters;
  };

  const availableFilters = computeAvailableFilters(menuData);

  useEffect(() => {
    if (activeFilter && !availableFilters[activeFilter]) {
      setActiveFilter(null);
    }
  }, [menuData, availableFilters, activeFilter]);

  const handleFilterChange = (filterType) => {
    setActiveFilter(filterType);
  };

  const itemPassesFilters = (item) => {
    if (!activeFilter) {
      return true; // Show all items if no filter is active
    }
    return item[activeFilter];
  };

  const waitingForWineInfo = uploadedImages.map(
    (image) => !image.wineResolved
  );

  const renderRestaurantName = () => {
    if (readOnly && restaurant) {
      return (
        <>
          <h2 ref={menuNameRef} className="menu-name subtitle-text">{restaurant.name}</h2>
          <div className="no-venues-found-container">
            <p className="no-venues-found-text">{restaurant.streetAddress}</p>
          </div>
          <p className="shared-label">
            Shared {new Date(menuUploadDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })},
            {new Date(menuUploadDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
          </p>
        </>
      );
    }
    if (!menuData || menuData.length === 0) {
      return (
        <>
          <h2 ref={menuNameRef} className="menu-name subtitle-text">Menu</h2>
          {userLocation && nearbyVenues && nearbyVenues.length === 0 && !userExplicitlyNotAtVenue && (
            <div className="no-venues-found-container">
              <p className="no-venues-found-text">No venues found near you</p>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <h2 ref={menuNameRef} className="menu-name subtitle-text">
          {selectedVenue ? selectedVenue.displayName.text : menuData[0].name}
        </h2>
        {userLocation && nearbyVenues && nearbyVenues.length === 0 && !userExplicitlyNotAtVenue && (
          <div className="no-venues-found-container">
            <p className="no-venues-found-text">No venues found near you</p>
          </div>
        )}
        {!userLocation && !userExplicitlyNotAtVenue && (
          <div className="location-prompt-container">
            <p className="location-prompt-text">Enable location services to get better insights about restaurants</p>
            <button className="location-button" onClick={handleLocationAccess}>
              Enable
            </button>
          </div>
        )}
        {(userLocation || userExplicitlyNotAtVenue) && (
          <div className="not-where-container">
            <button
              className="not-where-button"
              onClick={() => setIsVenueSelectorOpen(true)}
            >
              {userExplicitlyNotAtVenue ? "Select a venue" : "Not where you are?"}
            </button>
          </div>
        )}
        {readOnly && (
          <p className="shared-label">
            Shared {new Date(menuUploadDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}, {new Date(menuUploadDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
          </p>
        )}
      </>
    );
  };

  // **Moved the error check after all hooks**
  if (error) {
    return (
      <MessageModal
        title="Error"
        message={error}
        onClose={() => setError('')}
      />
    );
  }

  return (
    <div className="menu-display">

      {tutorialStep >= 0 && tutorialStep < tutorialSteps.length && (
        <AdvicePopup
          text={tutorialSteps[tutorialStep].text}
          onClose={handleCloseTutorial}
          onFinish={handleFinishTutorial}
          targetRef={tutorialSteps[tutorialStep].targetRef}
          index={tutorialStep}
          total={tutorialSteps.length}
          onPrevious={handlePreviousTutorialStep}
          onNext={handleNextTutorialStep}
        />
      )}

      {/* Restaurant Name */}
      {renderRestaurantName()}

      {/* Venue selector button */}
      {isVenueSelectorOpen && (
        <VenueSelector
          venues={nearbyVenues}
          onVenueSelect={handleVenueSelect}
          onClose={() => setIsVenueSelectorOpen(false)}
        />
      )}

      {/* Question box */}
      <div className="question-container">
        <form onSubmit={handleQuestionSubmit}>
          <div className="question-input-wrapper">
            <input
              ref={questionInputRef}
              type="text"
              placeholder="Ask questions..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="question-input text-normal"
              disabled={isLoading}
            />
            {!readOnly &&
              <div className="question-icons">
                <button
                  type="button"
                  className="share-button"
                  onClick={handleShareClick}
                  disabled={isLoading || isShareLoading}
                >
                  {isShareLoading ? (
                    <div className="share-loading-spinner">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    <img src="../images/share.png" alt="Share" className="share-icon" />
                  )}
                </button>
              </div>
            }
          </div>
        </form>
      </div>

      {/* Add the list of asked (and answered) questions if there are any */}
      {qaList.length > 0 &&
        <div className="qa-list">
          {qaList.map((qa) => (
            <div key={qa.id} className="qa-wrapper">
              <div className="qa-item">
                <div className="qa-header">
                  <span className="text-normal" onClick={() => toggleAnswerExpansion(qa.id)}>
                    {qa.question}
                  </span>
                  <span
                    className={`expand-arrow ${qa.isExpanded ? 'expanded' : ''}`}
                    onClick={() => toggleAnswerExpansion(qa.id)}
                  ></span>
                </div>
                {qa.isExpanded && (
                  <div className="qa-content">
                    <div
                      className="qa-answer"
                      dangerouslySetInnerHTML={{ __html: qa.answer }}
                    />
                  </div>
                )}
              </div>
              <button
                className="remove-button"
                onClick={(e) => {
                  e.stopPropagation();
                  removeAnswer(qa.id);
                }}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      }

      <div ref={filterButtonRef}>
        <FilterButtons
          availableFilters={availableFilters}
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
        />
      </div>

      <div ref={menuPreviewRef} className="image-tray">
        {uploadedImages &&
          uploadedImages.map((image, index) => (
            <div key={index} className="image-preview-container">
              <img
                src={image.url}
                alt={`Uploaded Menu ${index + 1}`}
                className="menu-image-preview"
                onClick={() => openImageModal(image.url)}
              />
              {image.baseLoading && (
                <div className="image-loading-spinner">
                  <div className="spinner"></div>
                </div>
              )}
              {image.error && !readOnly && handleRetryUpload && (
                <div className="error-indicator" onClick={() => handleRetryUpload(index)}>
                  ❌
                </div>
              )}
              {!readOnly && handleRemoveImage && (
                <button
                  className="remove-image-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              )}
            </div>
          ))}
        {!readOnly && handleAddImage && (
          <button className="add-image-button" onClick={handleAddImage}>
            +
          </button>
        )}
      </div>

      {isImageModalOpen && (
        <div className="image-modal-overlay" onClick={closeImageModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal-button" onClick={closeImageModal}>
              &times;
            </button>
            <img src={modalImageSrc} alt="Preview" className="modal-image" />
          </div>
        </div>
      )}

      {(!menuData || menuData.length === 0) ? (
        <div className="no-menu-container">
          <span className="no-menu-data-warning">
            No menu data
            <img src={sadDog} alt="Sad Collie" className="sad-dog-image" />
          </span>
        </div>
      ) : (
        (() => {
          try {
            return menuData.map((menu, menuIndex) => (
              <div key={menuIndex} className="menu-section">
                {menu && menu.courses && Array.isArray(menu.courses) ? (
                  menu.courses.map((course, index) => {
                    const filteredItems = course.i.filter(itemPassesFilters);

                    if (filteredItems.length === 0) {
                      return null; // Don't render courses with no matching items
                    }

                    return (
                      <div className="course-section" key={index}>
                        <h3 ref={courseNameTitleRef} className="course-name main-title-text">{course && course.e}</h3>
                        <div ref={itemCardRef} className="menu-items">
                          {filteredItems.map((item, idx) => (
                            <MenuItemCard
                              key={idx}
                              item={item}
                              addToBasket={addToBasket}
                              removeFromBasket={removeFromBasket}
                              basketItems={basketItems}
                              currency={currency}
                              courseName={course.e}
                              openDescriptionModal={openDescriptionModal}
                              isWineInfoPending={readOnly ? true : waitingForWineInfo[menuIndex]}
                              menuData={menuData}
                              disableDescriptionModal={readOnly ? false : disableDescriptionModal}
                              profile={profile}
                              setProfile={setProfile}
                              fadeIn={fadeIn}
                              readOnly={readOnly}
                              useWhiteBackground={true}
                              setFadeIn={setFadeIn}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>{/* No courses available for this menu. */}</p>
                )}
              </div>
            ));
          } catch (error) {
            console.error('Error mapping menuData:', error);
            console.log('menuData:', menuData);
            return (
              <div className="error-container">
                <span className="error-message">
                  An error occurred while processing the menu data.
                </span>
              </div>
            );
          }
        })()
      )}

      {isLoadingNewMenu && <BlankCard />}

      {isDescriptionModalOpen && selectedItem && !disableDescriptionModal && (
        <ItemDescriptionModal
          item={selectedItem}
          closeModal={closeDescriptionModal}
          addToBasket={addToBasket}
          removeFromBasket={removeFromBasket}
          basketItems={basketItems}
          currency={currency}
          courseName={selectedItem.courseName}
          getNextItem={getNextItem}
          getPreviousItem={getPreviousItem}
          isFirstItem={isFirstItem(selectedItem)}
          isLastItem={isLastItem(selectedItem)}
          menuData={menuData}
          openDescriptionModal={openDescriptionModal}
          winePairingsFetched={readOnly ? true : winePairingsFetched}
          menuName={selectedVenue ? selectedVenue.displayName.text : menuData[0].name}
        />
      )}

      <QRCodeModal
        isOpen={isQRCodeModalOpen}
        onClose={() => setIsQRCodeModalOpen(false)}
        qrCodeUrl={qrCodeUrl}
        shareType={shareType}
        setShareType={setShareType}
        isLoading={isLoading}
        menuName={selectedVenue ? selectedVenue.displayName.text : (menuData[0]?.name || "")}
        basketItems={basketItems}
      >
        {qrCodeUrl && <QRCodeCanvas value={qrCodeUrl} size={256} />}
      </QRCodeModal>
    </div>
  );
}

export default MenuDisplay;
