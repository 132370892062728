// src/components/NotFound.js
import '../styles/HomePage.css';
import sadDog from '../images/sad-dog.png';
import { useNavigate } from 'react-router-dom';  // Import useNavigate instead of useHistory

function NotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="header-center" onClick={() => navigate('/')}>
        <h1 style={{ cursor: 'pointer' }}>MENU VOUS</h1>
      </div>
      <div className="centered-content">
        <h1>404 - Page Not Found</h1>
        <h2>The page you are looking for does not exist.</h2>
        <img src={sadDog} alt="Sad Collie" className="sad-dog-image" onClick={() => navigate('/')}/>
      </div>
    </div>
  );
}

export default NotFound;
