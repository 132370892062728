import React from 'react';
import "../styles/Discover.css";

function MenuPreviewCard({ menu, onClick }) {
  return (
    <div className="menu-preview-card" onClick={onClick}>
      {menu.imagePreview ? (
        <img src={menu.imagePreview} alt="Menu Preview" className="menu-preview-image" />
      ) : (
        <div className="menu-no-image">
          <p>No Image Available</p>
        </div>
      )}
      <div className="menu-preview-info">
        <p className="menu-scanned-at">
          Scanned at: {new Date(menu.scannedAt).toLocaleString()}
        </p>
      </div>
    </div>
  );
}

export default MenuPreviewCard;
