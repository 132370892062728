import { compressImage } from '../api'; // If needed

// Function to compress a list of files and log their sizes before and after compression
export const compressFiles = async (files) => {
    const compressedFiles = await Promise.all(files.map(async (file) => {
        const originalSizeKB = (file.size / 1024).toFixed(2);
        console.log(`Original image size: ${originalSizeKB} KB`);
        const compressedFile = await compressImage(file);
        const compressedSizeKB = (compressedFile.size / 1024).toFixed(2);
        console.log(`Compressed image size: ${compressedSizeKB} KB`);
        return compressedFile;
    }));
    return compressedFiles; // Return the array of compressed files
};

// Add UIDs to the menus. First menu starts at 1, second at 1001 etc.
// This ensures each item definitely has a UNIQUE ID.
export const addUIDs = (menuData, index) => {
    let uid = 1000 * index + 1;
    try {
        for (const course of menuData.courses) {
            for (const item of course.i) {
                item.uid = uid++;
            }
        }
    } catch (error) {
        console.error('Error in addUIDs:', error);
    }

    console.log("Chunk with UIDs: ", menuData);
};

// Pull out a new menu with just the wines
export const extractWineItems = (menuData) => {
    let currMenu = menuData

    console.log("Structure going in to extractWineItems: ", currMenu);
    const wineData = { courses: [] };

    try {
        for (const course of currMenu.courses) {
            const newCourse = {
                e: course.e,
                o: course.o,
                i: course.i.filter(item => item.is_wine)
            };

            if (newCourse.i.length > 0) {
                wineData.courses.push(newCourse);
            }
        }
    } catch (error) {
        console.error('Error in extractWineItems:', error);
    }

    return wineData;
};

export const findWineByUid = (uid, wineMenu) => {
    try {
        return wineMenu.wines.find(wine => wine.uid === uid) || null;
    } catch (error) {
        console.error('Error in findWineByUid:', error);
        return null;
    }
};

// Add a single 'wineMenu' to the 'baseMenus' array (at the specified index)
export const addWinesToMenu = (baseMenus, wineMenu, index) => {
    // Clone the array to avoid mutating the original data
    const fullMenus = JSON.parse(JSON.stringify(baseMenus));

    try {
        // Ensure the specified index exists and is valid
        const baseMenu = fullMenus[index];
        if (!baseMenu) {
            throw new Error(`No base menu found at index ${index}`);
        }

        for (const course of baseMenu.courses) {
            for (const item of course.i) {
                if (item.is_wine) {
                    const wine = findWineByUid(item.uid, wineMenu);
                    if (wine) {
                        item.wine_info = JSON.parse(JSON.stringify(wine));
                    }
                }
            }
        }
    } catch (error) {
        console.error('Error in addWinesToMenu:', error);
    }

    return fullMenus;
};

export function addMenuPairingsToMenuData(menuData, pairedMenuData) {
    // Create a mapping from wine UID to menu_pairings
    const pairingMap = {};
    try {
      if (!pairedMenuData.wines || !Array.isArray(pairedMenuData.wines)) {
        console.error('Invalid pairedMenuData structure:', pairedMenuData);
        return menuData;
      }

      pairedMenuData.wines.forEach((wine) => {
        if (wine && wine.uid) {
          pairingMap[wine.uid] = wine.menu_pairings;
        }
      });

      // Update menuData with the menu_pairings
      const updatedMenuData = menuData.map((menu) => {
        if (!menu.courses || !Array.isArray(menu.courses)) {
          console.error('Invalid menu structure:', menu);
          return menu;
        }

        const updatedCourses = menu.courses.map((course) => {
          if (!course.i || !Array.isArray(course.i)) {
            console.error('Invalid course structure:', course);
            return course;
          }

          const updatedItems = course.i.map((item) => {
            if (item.is_wine && item.wine_info && pairingMap[item.uid]) {
              return {
                ...item,
                wine_info: {
                  ...item.wine_info,
                  menu_pairings: pairingMap[item.uid],
                },
              };
            }
            return item;
          });
          return {
            ...course,
            i: updatedItems,
          };
        });
        return {
          ...menu,
          courses: updatedCourses,
        };
      });
      return updatedMenuData;
    } catch (error) {
      console.error('Error in addMenuPairingsToMenuData:', error);
      return menuData;
    }
  }