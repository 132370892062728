// src/components/Footer.js

import React, { useState } from 'react';
import '../styles/Footer.css';
import wineGlassIcon from '../images/wine-glass-logo.png';


const sectionContent = {
    'ABOUT': 'Menu Vous is a passion project dedicated to enhancing your dining experience...',
    'FAQs': 'Find answers to frequently asked questions about our service...',
    'GIVE FEEDBACK': 'a.o.luxton@gmail.com',
    'CONTACT': 'a.o.luxton@gmail.com',
    'PRIVACY POLICY': 'We respect your privacy. Read our full privacy policy here...',
    'TERMS OF SERVICE': 'By using our service, you agree to the following terms...'
  };


const Footer = () => {
    const [expandedSection, setExpandedSection] = useState(null);
  
    const toggleSection = (section) => {
      setExpandedSection(expandedSection === section ? null : section);
    };
  
    const handleGiftClick = () => {
      window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=a.o.luxton@gmail.com&currency_code=GBP&amount=4.50', '_blank');
    };
  
    return (
      <footer className="footer">
        <div className="footer-content">
          <h2 className="footer-title">MENU VOUS</h2>
          <div className="footer-main">
            <img src={wineGlassIcon} alt="Wine Glass" className="footer-icon" />
            <p className="footer-subheading">We love helping people enjoy dining as our side-gig.</p>
            <p className="footer-body">Gift a glass of wine (£4.50) to help us keep the Menu Vous dream alive</p>
            <button className="footer-gift-button" onClick={handleGiftClick}>
              <span role="img" aria-label="Padlock">🔒</span> Send a gift
            </button>
          </div>
          <div className="footer-links">
            {Object.keys(sectionContent).map((link) => (
              <div key={link} className="footer-link-container">
                <button className="footer-link" onClick={() => toggleSection(link)}>
                  {link}
                </button>
                {expandedSection === link && (
                  <div className="footer-expanded">
                    <p>{sectionContent[link]}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <p className="footer-copyright">&copy; 2024, A. Luxton. All rights reserved.</p>
        </div>
      </footer>
    );
  };
  
  export default Footer;