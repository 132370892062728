import React, { useState, useEffect } from 'react';
import { updateUserProfile } from '../api';
import { auth, googleProvider } from '../firebase';
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
} from 'firebase/auth';
import '../styles/AuthModal.css';
import PreferenceQuiz from './PreferenceQuiz';
import MessageModal from './MessageModal';

function AuthModal({ onClose }) {
  const [authOption, setAuthOption] = useState(null); // 'email', 'signIn', 'signUp'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPreferenceQuiz, setShowPreferenceQuiz] = useState(false);
  const [error, setError] = useState('');
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);

  const defaultProfile = {
    dietaryPreferences: {
      allergies: [],
      'gluten-free': false,
      pescatarian: false,
      vegan: false,
      vegetarian: false,
    },
    cuisines: [],
    diningInterests: [],
    wineKnowledgeLevel: "I don't care",
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setProfile(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    if (isSigningIn) return; // Prevent multiple sign-in attempts
    setIsSigningIn(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const additionalUserInfo = getAdditionalUserInfo(result);
      const isNewUser = additionalUserInfo.isNewUser;
  
      if (isNewUser) {
        setIsNewUser(true);
        setWelcomeMessage(
          'Welcome to Menu Vous! Please complete the preferences questionnaire so we can tailor your experience to your needs'
        );
        setShowPreferenceQuiz(true);
      } else {
        let displayName = user.displayName
          ? user.displayName.split(' ')[0]
          : user.email.split('@')[0];
        setWelcomeMessage(`Welcome back, ${displayName}!`);
        setTimeout(() => {
          setWelcomeMessage('');
          onClose();
        }, 2000);
      }
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      setError('Failed to sign in with Google. Please try again.');
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password fields cannot be empty.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format.');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setWelcomeMessage(`Welcome back, ${email}`);
      setTimeout(() => {
        setWelcomeMessage('');
        onClose();
      }, 2000);
    } catch (signInError) {
      const errorCode = signInError.code;
      const errorMessage = signInError.message;

      if (errorCode === 'auth/user-not-found') {
        setError('No account found with this email. Please sign up.');
      } else if (errorCode === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else {
        console.error('Error signing in:', signInError);
        setError(`Authentication failed: ${errorMessage}`);
      }
    }
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password fields cannot be empty.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format.');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setIsNewUser(true);
      setWelcomeMessage(
        'Welcome to Menu Vous! Please complete the preferences questionnaire so we can tailor your experience to your needs'
      );
      setShowPreferenceQuiz(true);
    } catch (createError) {
      const errorCode = createError.code;
      const errorMessage = createError.message;

      if (errorCode === 'auth/email-already-in-use') {
        setError('An account with this email already exists. Please sign in.');
      } else if (errorCode === 'auth/weak-password') {
        setError('Password should be at least 6 characters.');
      } else if (errorCode === 'auth/invalid-email') {
        setError('Invalid email format.');
      } else {
        console.error('Error creating account:', createError);
        setError(`Failed to create account: ${errorMessage}`);
      }
    }
  };

  const handlePreferenceQuizClose = async (updatedProfile) => {
    try {
      await updateUserProfile(updatedProfile);
      setProfile(updatedProfile);
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to update user profile. Please try again.');
    }
    setShowPreferenceQuiz(false);
    setIsNewUser(false);
    onClose();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <MessageModal
        title="Error"
        message={error}
        onClose={() => setError('')}
      />
    );
  }

  if (welcomeMessage) {
    return (
      <MessageModal
        title={isNewUser ? 'WELCOME!' : 'Welcome Back!'}
        message={welcomeMessage}
        onClose={() => {
          setWelcomeMessage('');
          if (!isNewUser) {
            onClose();
          }
        }}
      />
    );
  }

  if (showPreferenceQuiz && isNewUser) {
    return (
      <PreferenceQuiz
        onClose={handlePreferenceQuizClose}
        profile={profile || defaultProfile}
        setProfile={setProfile}
      />
    );
  }

  return (
    <div className="basket-modal-overlay">
      <div className="basket-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{authOption === 'signUp' ? 'SIGN UP' : 'LOG IN'}</h2>
        {error && <div className="error-message">{error}</div>}
        {authOption === null ? (
          <div className="auth-options">
            <button className="auth-button" onClick={handleGoogleSignIn} disabled={isSigningIn}>
              Continue with Google
            </button>
            <button className="auth-button" onClick={() => setAuthOption('email')}>
              Continue with Email
            </button>
            <button className="auth-button disabled" disabled>
              Continue with Apple
            </button>
          </div>
        ) : authOption === 'email' ? (
          <div className="auth-options">
            <button className="auth-button" onClick={() => setAuthOption('signIn')}>
              Sign In with Email
            </button>
            <button className="auth-button" onClick={() => setAuthOption('signUp')}>
              Sign Up with Email
            </button>
            <button className="auth-button" onClick={() => setAuthOption(null)}>
              Back
            </button>
          </div>
        ) : authOption === 'signIn' ? (
          <form className="email-form" onSubmit={handleEmailSignIn}>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="auth-button" type="submit">
              Sign In
            </button>
            <button
              className="auth-button"
              type="button"
              onClick={() => setAuthOption('email')}
            >
              Back
            </button>
          </form>
        ) : authOption === 'signUp' ? (
          <form className="email-form" onSubmit={handleEmailSignUp}>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password (min 6 characters)"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="auth-button" type="submit">
              Sign Up
            </button>
            <button
              className="auth-button"
              type="button"
              onClick={() => setAuthOption('email')}
            >
              Back
            </button>
          </form>
        ) : null}
      </div>
    </div>
  );
}

export default AuthModal;
