import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import ProfileModal from './ProfileModal';
import AuthModal from './AuthModal';
import { auth } from '../firebase';

function Header({ onBasketClick, basketItemCount, onLogoClick, profile, setProfile}) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleProfileClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`app-header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-left">
        <button className="profile-button" onClick={handleProfileClick}>
          <img src="../images/user.png" alt="Profile" className="profile-icon" />
        </button>
      </div>
      <div className="header-center" onClick={onLogoClick}>
        <h1 style={{ cursor: 'pointer' }}>MENU VOUS</h1>
      </div>
      <div className="header-right">
        <button className="basket-button" onClick={onBasketClick}>
          <img src="../images/receipt.png" alt="Basket" className="basket-icon" />
          {basketItemCount > 0 && <span className="basket-count">{basketItemCount}</span>}
        </button>
      </div>
      {isModalOpen && (
        user ? (
          <ProfileModal onClose={handleModalClose} profile={profile} setProfile={setProfile}/>
        ) : (
          <AuthModal onClose={handleModalClose} />
        )
      )}
    </header>
  );
}

export default Header;

