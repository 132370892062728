// components/VenueSelector.js

import React from 'react';
import '../styles/VenueSelector.css';

function VenueSelector({ venues, onVenueSelect, onClose }) {
  const handleVenueChange = (e) => {
    const selectedVenue = venues.find(venue => venue.id === e.target.value);
    onVenueSelect(selectedVenue);
  };

  // If a user isn't at a venue
  const handleNotAtVenue = () => {
    onVenueSelect({ notAtVenue: true});
  };

  return (
    <div className="venue-selector-overlay" onClick={onClose}>
      <div className="venue-selector-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <span className="quiz-heading">SELECT YOUR VENUE</span>
        <p><i>Select your venue</i></p>
        <div className="dropdown-container">
          <select onChange={handleVenueChange} className="venue-dropdown">
            <option value="">-- Select a Venue --</option>
            {venues.map((venue) => (
              <option key={venue.id} value={venue.id}>
                {venue.displayName.text}
              </option>
            ))}
          </select>
        </div>
        <p><i>Or</i></p>
        <button className="not-at-venue-button" onClick={handleNotAtVenue}>
          Not at a venue
        </button>
      </div>
    </div>
  );
}

export default VenueSelector;
