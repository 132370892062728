import React, { useState } from 'react';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import CharacteristicModal from './CharacteristicModal';

const CustomBarChart = ({ 
  data, 
  currentLevel, 
  colors, 
  characteristic, 
  menuName, 
  menuData,
  addToBasket,
  removeFromBasket,
  basketItems,
  currency,
  openDescriptionModal
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const maxValue = Math.max(...data.map(d => d.value));

  const adjustedData = data.map(d => ({
    ...d,
    adjustedValue: d.value === 0 ? maxValue * 0.05 : d.value
  }));

  const handleBarClick = (entry, index) => {
    setSelectedLevel(index + 1);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLevel(null);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '50px' }}>
      <ResponsiveContainer width="100%" height={50}>
        <BarChart 
          data={adjustedData} 
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barCategoryGap={0.0}
          barGap={0}
        >
          <Bar 
            dataKey="adjustedValue" 
            radius={[5, 5, 0, 0]}
            onClick={handleBarClick}
          >
            {adjustedData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={colors[index]} 
                fillOpacity={entry.value === 0 ? 0.3 : 1} 
                style={{ cursor: 'pointer' }}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div
        style={{
          position: 'absolute',
          left: `${20 * currentLevel - 10}%`,
          bottom: '-15px',
          width: '18px',
          height: '18px',
          borderRadius: '50%',
          backgroundColor: 'black',
          border: '5px solid white',
          transform: 'translateX(-50%)',
        }}
      />
      {showModal && (
        <CharacteristicModal
          characteristic={characteristic}
          level={selectedLevel}
          menuName={menuName}
          data={adjustedData}
          colors={colors}
          onClose={handleCloseModal}
          menuData={menuData}
          addToBasket={addToBasket}
          removeFromBasket={removeFromBasket}
          basketItems={basketItems}
          currency={currency}
          openDescriptionModal={openDescriptionModal}
        />
      )}
    </div>
  );
};

export default CustomBarChart;