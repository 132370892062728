// FilterButtons.js

import React from 'react';

const FilterButtons = ({ availableFilters, activeFilter, onFilterChange }) => {
  const filterLabels = {
    is_wine: 'Wine',
    is_food: 'Food',
    is_drink: 'Drink',
    is_afters: 'Dessert',
  };

  const filterCount = Object.values(availableFilters).filter(Boolean).length;

  if (filterCount <= 1) return null;

  const handleFilterClick = (filterType) => {
    const newActiveFilter = activeFilter === filterType ? null : filterType;
    onFilterChange(newActiveFilter);
  };

  return (
    <div className="filter-buttons">
      <button
        className={`filter-button ${activeFilter === null ? 'active' : ''}`}
        onClick={() => handleFilterClick(null)}
      >
        All
      </button>
      {Object.entries(availableFilters).map(([key, isAvailable]) =>
        isAvailable ? (
          <button
            key={key}
            className={`filter-button ${activeFilter === key ? 'active' : ''}`}
            onClick={() => handleFilterClick(key)}
          >
            {filterLabels[key]}
          </button>
        ) : null
      )}
    </div>
  );
};

export default FilterButtons;
