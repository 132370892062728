import React from 'react';
import '../styles/MessageModal.css';

function MessageModal({ title, message, onClose, onConfirm, showConfirm }) {
  return (
    <div className="message-modal-overlay">
      <div className="message-modal">
        <div className="modal-content">
          <h2 className="modal-heading">{title}</h2>
          <p className="message-content">{message}</p>
        </div>
        <button className="message-modal-close" onClick={onClose}>
          &#10005;
        </button>
        <div className="message-modal-buttons">
          {showConfirm ? (
            <>
              <button className="message-modal-confirm" onClick={onConfirm}>Enable</button>
              <button className="message-modal-cancel" onClick={onClose}>Not Now</button>
            </>
          ) : (
            <button className="message-modal-close-button" onClick={onClose}>Close</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageModal;