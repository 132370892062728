import React, { useState, useEffect, useCallback } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import PreferenceQuiz from './PreferenceQuiz';
import { getUserProfile, deleteUserAccount } from '../api';
import MessageModal from './MessageModal';
import '../styles/ProfileModal.css';

function ProfileModal({ onClose, profile, setProfile }) {
  const user = auth.currentUser;
  const [showPreferenceQuiz, setShowPreferenceQuiz] = useState(false);
  const [preferenceStep, setPreferenceStep] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');
  const [showSignOutConfirmation, setShowSignOutConfirmation] = useState(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      onClose();
    };

    return () => {
      window.onpopstate = null;
    };
  }, [onClose]);

  const handleOverlayClick = useCallback((event) => {
    if (event.target.className === 'profile-modal-overlay') {
      onClose();
    }
  }, [onClose]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const userProfile = await getUserProfile();
      setProfile(userProfile);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  }, [setProfile]);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user, fetchUserProfile]);

  const handleDeleteAccount = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteAccount = async () => {
    try {
      await deleteUserAccount();
      // Handle successful account deletion (e.g., sign out, redirect)
      auth.signOut();
      onClose();
    } catch (error) {
      console.error('Failed to delete account:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  //const handleChangeEmail = () => {
  //  // Implement email change logic
  //  console.log('Change email');
  //};
//
  //const handleChangePassword = () => {
  //  // Implement password change logic
  //  console.log('Change password');
  //};

  const handleOpenPreferences = (step) => {
    setPreferenceStep(step);
    setShowPreferenceQuiz(true);
  };

  const handleClosePreferences = () => {
    setShowPreferenceQuiz(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setLogoutMessage('Logged out successfully');
      setTimeout(() => {
        setLogoutMessage('');
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSignOutClick = () => {
    setShowSignOutConfirmation(true);
  };

  const confirmSignOut = async () => {
    try {
      await signOut(auth);
      setLogoutMessage('Logged out successfully');
      setTimeout(() => {
        setLogoutMessage('');
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!user) {
    return null;
  }

  const { displayName } = user;

  return (
    <div className="profile-modal-overlay" onClick={handleOverlayClick}>
      <div className="profile-modal">
        <button className="close-button" onClick={onClose}>&times;</button>
        <span className="profile-modal-heading">
          PROFILE SETTINGS
        </span>
        
        {!showDeleteConfirmation && !showSignOutConfirmation ? (
          <div className="profile-content">
            <p className="user-name">{displayName || ''}: {profile.points || 0} menus scanned</p>
            <div className="menu-item" onClick={handleSignOutClick}>
              <span>Sign out</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item" onClick={handleDeleteAccount}>
              <span>Delete account</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item disabled">
              <span>Change email</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item disabled">
              <span>Change password</span>
              <span className="chevron"></span>
            </div>
            <div className="preferences-heading">Preferences:</div>
            <div className="menu-item" onClick={() => handleOpenPreferences(0)}>
              <span>Dietary preferences</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item" onClick={() => handleOpenPreferences(1)}>
              <span>Cuisine interests</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item" onClick={() => handleOpenPreferences(2)}>
              <span>Dining types</span>
              <span className="chevron"></span>
            </div>
            <div className="menu-item" onClick={() => handleOpenPreferences(3)}>
              <span>Wine knowledge</span>
              <span className="chevron"></span>
            </div>
            <button className="scan-menu-button" onClick={handleSignOut}>Scan menu</button>

            </div>
        ) : showDeleteConfirmation ? (
          <div className="delete-confirmation">
            <h3>Are you sure you want to delete your account?</h3>
            <p>This action cannot be undone. All your data will be permanently deleted.</p>
            <div className="delete-buttons">
              <button className="cancel-delete" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
              <button className="confirm-delete" onClick={confirmDeleteAccount}>Delete Account</button>
            </div>
          </div>
        ) : (
          <div className="delete-confirmation">
            <h3>Are you sure you want to sign out?</h3>
            <p>You will need to log in again to access your account.</p>
            <div className="delete-buttons">
              <button className="cancel-delete" onClick={() => setShowSignOutConfirmation(false)}>Cancel</button>
              <button className="confirm-delete" onClick={confirmSignOut}>Sign Out</button>
            </div>
          </div>
        )}
        {showPreferenceQuiz && (
          <PreferenceQuiz
            onClose={handleClosePreferences}
            initialStep={preferenceStep}
            profile={profile}
            setProfile={setProfile}
          />
        )}
      </div>
      {logoutMessage && (
        <MessageModal 
          title="SIGNED OUT" 
          message={logoutMessage} 
          onClose={() => setLogoutMessage('')} 
        />
      )}
    </div>
  );
}

export default ProfileModal;