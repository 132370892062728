import React, { useEffect, useRef } from 'react';
import '../styles/AdvicePopup.css';

function AdvicePopup({ text, onClose, onFinish, targetRef, index, total, onPrevious, onNext }) {
  const popupRef = useRef(null);

  useEffect(() => {
    // Removed the handleClickOutside function and its event listener

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      } else if (event.key === 'ArrowLeft' && onPrevious) {
        onPrevious();
      } else if (event.key === 'ArrowRight' && onNext) {
        onNext();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, onPrevious, onNext]);

  useEffect(() => {
    const currentTarget = targetRef?.current;
    if (currentTarget) {
      currentTarget.classList.add('advice-popup-target');
    }

    return () => {
      if (currentTarget) {
        currentTarget.classList.remove('advice-popup-target');
      }
    };
  }, [targetRef]);

  useEffect(() => {
    if (popupRef.current) {
      const left = Math.max(10, Math.min((window.innerWidth - popupRef.current.offsetWidth) / 2, window.innerWidth - popupRef.current.offsetWidth - 10));
      const top = Math.max(10, Math.min((window.innerHeight - popupRef.current.offsetHeight) / 2, window.innerHeight - popupRef.current.offsetHeight - 10));

      popupRef.current.style.left = `${left}px`;
      popupRef.current.style.top = `${top}px`;
    }
  }, [popupRef]);

  useEffect(() => {
    if (targetRef?.current && popupRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();

      let left = targetRect.left + (targetRect.width / 2) - (popupRect.width / 2);
      let top;

      if (targetRect.top > popupRect.height + 20) {
        top = targetRect.top - popupRect.height - 10;
        popupRef.current.classList.remove('bottom');
        popupRef.current.classList.add('top');
      } else {
        top = targetRect.bottom + 10;
        popupRef.current.classList.remove('top');
        popupRef.current.classList.add('bottom');
      }

      left = Math.max(10, Math.min(left, window.innerWidth - popupRect.width - 10));

      popupRef.current.style.left = `${left}px`;
      popupRef.current.style.top = `${top}px`;
    } else if (popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const left = Math.max(10, Math.min((window.innerWidth - popupRect.width) / 2, window.innerWidth - popupRect.width - 10));
      const top = Math.max(10, Math.min((window.innerHeight - popupRect.height) / 2, window.innerHeight - popupRect.height - 10));

      popupRef.current.style.left = `${left}px`;
      popupRef.current.style.top = `${top}px`;
    }
  }, [targetRef]);

  const isFirstStep = index === 0;
  const isLastStep = index === total - 1;

  return (
    <>
      <div className="advice-popup-overlay"></div>
      <div ref={popupRef} className="advice-popup">
        <button className="advice-popup-close" onClick={onClose}>&times;</button>
        {index !== undefined && total !== undefined && (
          <p className="advice-popup-index">Getting started ({index + 1}/{total})</p>
        )}
        <p className="advice-popup-text" dangerouslySetInnerHTML={{ __html: text }}></p>
        <div className={`advice-popup-navigation ${isFirstStep ? 'first-step' : ''}`}>
          {!isFirstStep && (
            <button 
              className="advice-popup-nav-button secondary" 
              onClick={onPrevious}
            >
              Previous
            </button>
          )}
          {isLastStep ? (
            <button 
              className="advice-popup-nav-button primary" 
              onClick={onFinish}
            >
              Finish
            </button>
          ) : (
            <button 
              className={`advice-popup-nav-button primary ${isFirstStep ? 'full-width' : ''}`}
              onClick={onNext}
            >
              {isFirstStep ? 'Ok' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default AdvicePopup;
