import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import '../styles/QRCodeModal.css';
//import AuthModal from './AuthModal'; // Import the AuthModal component
import MessageModal from './MessageModal'; // Import the MessageModal component
//import { collection, doc, setDoc } from 'firebase/firestore'; // Firestore imports
//import { firestore } from '../firebase'; // Adjust the path based on your project structure

const QRCodeModal = ({ isOpen, onClose, qrCodeUrl, shareType, setShareType, onShareTypeChange, isLoading, children, menuName, basketItems }) => {
  const [user, setUser] = useState(auth.currentUser);
  /* eslint-disable no-unused-vars */
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [sessionQrCodeUrl, setSessionQrCodeUrl] = useState('');
  /* eslint-enable no-unused-vars */
  const [error, setError] = useState(''); // Handle errors
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Function to save the session (order) to Firestore
 //const saveSessionToFirestore = async () => {
 //  try {
 //    console.log("Saving session to firebase!");
 //    setIsShareLoading(true);

 //    // Generate a new session ID
 //    const sessionId = `session_${Date.now()}`;

 //    // Save the session to Firestore
 //    await setDoc(doc(collection(firestore, 'sessions'), sessionId), {
 //      sessionId: sessionId,
 //      ownerId: user.uid,
 //      ownerName: user.displayName,
 //      basketItems: basketItems.map(item => ({
 //        id: item.id,
 //        name: item.name,
 //        quantity: item.quantity,
 //        price: item.price,
 //      })),
 //      participants: [], // Empty participants array for now
 //    });

 //    // Return the URL for the session
 //    return `${window.location.origin}/join-session/${sessionId}`;
 //  } catch (error) {
 //    console.error('Error saving session to Firestore:', error);
 //    setError('Error saving session: ' + error.message);
 //    throw error;
 //  } finally {
 //    setIsShareLoading(false);
 //  }
 //};

  // Handle the share button click
  const handleShare = async () => {
    try {
      if (shareType === 'order') {
        console.log("sharing order!");
        // Save the session and generate a session-specific QR code URL
        //const sessionUrl = await saveSessionToFirestore();
        //setSessionQrCodeUrl(sessionUrl);
      } else {
        // If it's just the menu, handle normal sharing logic (not shown here)
      }
  
      // Proceed with sharing the generated QR code URL via Web Share API or another method
      const fullName = user?.displayName || 'A friend';
      const firstName = fullName.split(' ')[0]; // Take the first part of the name
  
      if (navigator.share) {
        await navigator.share({
          title: `Menu from ${menuName}`,
          text: `${firstName} shared a menu from ${menuName} with you on Menu Vous.`,
          url: sessionQrCodeUrl || qrCodeUrl, // Use session URL if sharing order
        });
      } else {
        alert('Web Share API is not supported in your browser. You can copy the link manually.');
      }
    } catch (error) {
      if (error.message !== 'Share canceled') {
        console.error('Error sharing:', error);
        setError('Error sharing: ' + error.message); // Handle sharing errors
      }
    }
  };
  

  if (!isOpen) return null;

  if (error) {
    return (
      <MessageModal
        title="Error"
        message={error}
        onClose={() => {
          setError('');
          onClose(); // Close the modal after error is dismissed
        }}
      />
    );
  }

  const dynamicText = shareType === 'menu'
    ? "People will see the menu when they scan the QR code."
    : "People will see the menu and be able to add to the order when they scan the QR code.";

  return (
    <div className="qr-code-modal-overlay" onClick={onClose}>
      <div className="qr-code-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &#10005;
        </button>
        <h2 className="modal-heading main-title-text">SHARE MENU</h2>
        <div className="share-type-selector">
          <button
            className={`share-type-button ${shareType === 'menu' ? 'active' : ''}`}
            //onClick={() => onShareTypeChange('menu')}
            //disabled={isShareLoading}
          >
            MENU ONLY
          </button>
          <button
            className={`share-type-button ${shareType === 'order' ? 'active' : ''}`}
            //onClick={() => onShareTypeChange('order')}
            //disabled={isShareLoading}
            disabled={true}
          >
            MENU & ORDER
          </button>
        </div>
        <h3 className="scan-instruction main-title-text">
          {shareType === 'menu' ? 'SCAN TO SEE MENU' : 'SCAN TO JOIN ORDER'}
        </h3>
        <div className="qr-code-content">
          {isShareLoading ? (
            <div className="loading-spinner"></div>
          ) : sessionQrCodeUrl || qrCodeUrl ? (
            <>
              {children}
              <p className="qr-code-instruction text-normal">{dynamicText}</p>
            </>
          ) : (
            <p className="qr-code-instruction text-normal">Error generating QR code. Please try again.</p>
          )}
        </div>
        <button 
          className="share-to-socials-button"
          //disabled={isShareLoading || !(sessionQrCodeUrl || qrCodeUrl)}
          onClick={handleShare}
        >
          {isShareLoading ? 'Generating...' : `Share ${shareType === 'menu' ? 'menu' : 'menu & order'}`}
        </button>
      </div>
    </div>
  );
};

export default QRCodeModal;
