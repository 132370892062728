import React, { useState, useEffect } from 'react';
import "../styles/Discover.css";
import sadDog from '../images/sad-dog.png';
import { getAllRestaurants, getMenusByRestaurant } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuPreviewCard from './MenuPreviewCard';

function DiscoverPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [menus, setMenus] = useState([]);
  const [isLoadingMenus, setIsLoadingMenus] = useState(false);
  const [isLoadingRestaurants, setIsLoadingRestaurants] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchRestaurants();

    // Set up back button listener
    const handlePopState = () => {
      if (selectedRestaurant) {
        handleBackToRestaurants();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [selectedRestaurant]); // Add selectedRestaurant as dependency

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredRestaurants(restaurants);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = restaurants.filter(restaurant =>
        restaurant.name.toLowerCase().includes(lowerCaseQuery) ||
        restaurant.streetAddress.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredRestaurants(filtered);
    }
  }, [searchQuery, restaurants]);

  const fetchRestaurants = async () => {
    try {
      setIsLoadingRestaurants(true);
      const data = await getAllRestaurants();
      if (data.success) {
        setRestaurants(data.restaurants);
        setFilteredRestaurants(data.restaurants);
      } else {
        console.error('Failed to fetch restaurants');
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    } finally {
      setIsLoadingRestaurants(false);
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRestaurantClick = async (restaurant) => {
    // Push a new entry to browser history when selecting a restaurant
    window.history.pushState({ restaurantView: true }, '', location.pathname);
    
    setSelectedRestaurant(restaurant);
    setIsLoadingMenus(true);
    try {
      const data = await getMenusByRestaurant(restaurant._id);
      if (data.success) {
        setMenus(data.menus);
      } else {
        console.error('Failed to fetch menus for restaurant');
      }
    } catch (error) {
      console.error('Error fetching menus:', error);
    } finally {
      setIsLoadingMenus(false);
    }
  };

  const handleMenuClick = (menu) => {
    navigate(`/menu/${menu._id}`);
  };

  const handleBackToRestaurants = () => {
    setSelectedRestaurant(null);
    setMenus([]);
  };

  const renderRestaurants = () => {
    if (isLoadingRestaurants) {
      return (
        <div className="loading-overlay">
          <div className="loading-content">
            <h2 className="loading-title"> </h2>
            <div className="loader"></div>
            <h2 className="loading-subtitle"> </h2>
          </div>
        </div>
      );
    }

    if (filteredRestaurants.length === 0) {
      return (
        <div className="no-menu-container">
          <span className="no-menu-data-warning">
            No restaurants found!
            <img src={sadDog} alt="Sad Collie" className="sad-dog-image" />
          </span>
        </div>
      );
    }

    return filteredRestaurants.map((restaurant) => (
      <div key={restaurant._id} className="restaurant-card" onClick={() => handleRestaurantClick(restaurant)}>
        <h3 className="restaurant-name">{restaurant.name}</h3>
        <p className="restaurant-address">{restaurant.streetAddress}</p>
        <p className="restaurant-menu-count">Menus available: {restaurant.menuCount}</p>
      </div>
    ));
  };

  const renderMenus = () => {
    if (isLoadingMenus) {
      return (
        <div className="loading-overlay">
          <div className="loading-content">
            <h2 className="loading-title"> </h2>
            <div className="loader"></div>
            <h2 className="loading-subtitle"> </h2>
          </div>
        </div>
      );
    }

    if (menus.length === 0) {
      return (
        <div className="no-menu-container">
          <span className="no-menu-data-warning">
            No menus found for this restaurant!
            <img src={sadDog} alt="Sad Collie" className="sad-dog-image" />
          </span>
        </div>
      );
    }

    return menus.map((menu) => (
      <MenuPreviewCard key={menu._id} menu={menu} onClick={() => handleMenuClick(menu)} />
    ));
  };

  return (
    <div className="menu-display">
      {!selectedRestaurant ? (
        <>
          <h2 className="menu-name subtitle-text">Discover Restaurants</h2>
          <div className="question-container">
            <form>
              <div className="question-input-wrapper">
                <input
                  type="text"
                  placeholder="Search to discover places..."
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  className="question-input text-normal"
                />
              </div>
            </form>
          </div>
          <div className="restaurant-list">
            {renderRestaurants()}
          </div>
        </>
      ) : (
        <div className="menus-container">
          <button onClick={handleBackToRestaurants} className="back-button">
            Back to Restaurants
          </button>
          <div className="menu-name-container">
            <h2 className="menu-name subtitle-text">{selectedRestaurant.name} Menus</h2>
          </div>
          <div className="menu-list">
            {renderMenus()}
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscoverPage;