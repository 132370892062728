// src/components/WineDescriptionModal.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/WineDescriptionModal.css';
import PriceSelectionModal from './PriceSelectionModal';
import CustomBarChart from './BarChart';
import MenuItemCard from './MenuItemCard';
import ItemDescriptionModal from './ItemDescriptionModal';

const getConfidenceLevel = (confidence) => {
    if (confidence >= 0.8) return { short: 'HC', long: 'High confidence' };
    if (confidence >= 0.6) return { short: 'MC', long: 'Medium confidence' };
    return { short: 'LC', long: 'Low confidence' };
  };
  
  const ConfidenceIndicator = ({ confidence }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const level = getConfidenceLevel(confidence);
    
    return (
      <span className="confidence-indicator-wrapper">
        <span 
          className="confidence-indicator" 
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {level.short}
        </span>
        {showTooltip && (
          <span className="confidence-tooltip">
            {`${level.long} (${(confidence * 100).toFixed(0)}%)`}
          </span>
        )}
      </span>
    );
  };
  

const WineDescriptionModal = ({
    item,
    closeModal,
    addToBasket,
    removeFromBasket,
    basketItems,
    currency,
    courseName,
    getNextItem,
    getPreviousItem,
    isFirstItem,
    isLastItem,
    menuData,
    openDescriptionModal,
    winePairingsFetched,
    menuName,
  }) => {
    const [isClosing, setIsClosing] = useState(false);
    const [currentItem, setCurrentItem] = useState(item);
    const [swipeDirection, setSwipeDirection] = useState(null);
    const [showPriceSelectionModal, setShowPriceSelectionModal] = useState(false);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const modalRef = useRef(null);
    const [showCharacteristicModal, setShowCharacteristicModal] = useState(false);
    const [isSwiping, setIsSwiping] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isInitialRender, setIsInitialRender] = useState(true);
  const [isSwipeIndicatorVisible, setIsSwipeIndicatorVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSwipeIndicatorVisible(true);
    }, 500); // Delay of 500ms before showing the swipe indicator
  
    return () => clearTimeout(timer);
  }, []);

  const validVariants = Object.entries(currentItem.p || {}).reduce((acc, [key, value]) => {
    if (value && value.name !== "" && value.price > 0) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const validVariantEntries = Object.entries(validVariants);
  const singleValidVariant = validVariantEntries.length === 1 ? validVariantEntries[0][1] : null;

  const handleClose = useCallback(() => {
    if (showCharacteristicModal) {
      setShowCharacteristicModal(false);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        closeModal();
        window.history.back();
      }, 300);
    }
  }, [closeModal, showCharacteristicModal]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    const handlePopState = (event) => {
      event.preventDefault();
      handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    window.addEventListener('popstate', handlePopState);

    if (!showCharacteristicModal) {
      window.history.pushState(null, '');
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', handleEscape);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleClose, showCharacteristicModal]);


  const getPrice = () => {
    if (typeof currentItem.p === 'number') {
      return currentItem.p;
    } else if (singleValidVariant) {
      return singleValidVariant.price;
    } else {
      const validPrices = Object.values(validVariants).map(variant => variant.price);
      return validPrices.length > 0 ? Math.min(...validPrices) : null;
    }
  };

  const handleAddToOrder = () => {
    if (typeof currentItem.p === 'number' || singleValidVariant) {
      const itemId = `${currentItem.e}${singleValidVariant ? ` - ${singleValidVariant.name}` : ''}`;
      const price = typeof currentItem.p === 'number' ? currentItem.p : singleValidVariant.price;
      addToBasket(itemId, {
        id: itemId,
        name: currentItem.e,
        originalName: currentItem.o,
        price: price,
        variant: singleValidVariant ? singleValidVariant.name : '',
      });
      closeModal();
    } else {
      setShowPriceSelectionModal(true);
    }
  };

  const handleSwipe = (direction) => {
    if (!getNextItem || !getPreviousItem) return;
    
    const newItem = direction === 'left' ? getNextItem(currentItem) : getPreviousItem(currentItem);
    if (newItem && ((direction === 'left' && !isLastItem) || (direction === 'right' && !isFirstItem))) {
        setSwipeDirection(direction);
        setTimeout(() => {
            setCurrentItem(newItem);
            setSwipeDirection(null);
        }, 300);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setIsSwiping(false);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchEnd = () => {
    if (!isSwiping) return;

    const swipeThreshold = 100; // Minimum distance for a swipe
    const swipeDistance = touchEndX - touchStartX;

    if (Math.abs(swipeDistance) > swipeThreshold) {
      if (swipeDistance > 0) {
        handleSwipe('right');
      } else {
        handleSwipe('left');
      }
    }

    // Reset touch coordinates and swiping state
    setTouchStartX(0);
    setTouchEndX(0);
    setIsSwiping(false);
  };


  const renderWineInfo = (label, value, confidence) => (
    <div className="wine-info-column">
      <h4 className="wine-info-heading">
        {label}
        {confidence !== undefined && <ConfidenceIndicator confidence={confidence} />}
      </h4>
      <p className="wine-info-text">{value || 'N/A'}</p>
    </div>
  );

  const getCharacteristicCounts = (characteristic) => {
    const counts = [0, 0, 0, 0, 0];
    menuData.forEach(menu => {
      menu.courses.forEach(course => {
        course.i.forEach(wine => {
          if (wine.is_wine && wine.wine_info?.characteristics?.[characteristic]?.level) {
            counts[wine.wine_info.characteristics[characteristic].level - 1]++;
          }
        });
      });
    });
    return counts;
  };

  const openCharacteristicModal = (characteristic) => {
    setShowCharacteristicModal(true);
    window.history.pushState(null, '');
  };


  const characteristicColors = {
    body: ['#E73A3B', '#CA2A31', '#A5213D', '#7E153E', '#6A123E'],
    sweetness: ['#FFEEA4', '#FFE27B', '#FFD652', '#FFCA28', '#FFBF00'],
    acidity: ['#E6F987', '#E4F783', '#C6EE66', '#A9DC5B', '#8CBD4A'],
    tannin: ['#B69371', '#9B7356', '#80523C', '#653221', '#4A1207']
  };

  const renderCharacteristic = (characteristic) => {
    const info = currentItem.wine_info?.characteristics?.[characteristic];
    if (!info) return null;
  
    const counts = getCharacteristicCounts(characteristic);
    const level = info.level;
  
    const chartData = counts.map((count, index) => ({
      name: `Level ${index + 1}`,
      value: count
    }));
  
    const getCharacteristicHeading = () => {
      const headings = {
        body: ['Very Light Body', 'Light Body', 'Medium Body', 'Full Body', 'Extra Full Body'],
        sweetness: ['Bone Dry', 'Dry', 'Off-Dry', 'Sweet', 'Very Sweet'],
        acidity: ['Very Low Acidity', 'Low Acidity', 'Medium Acidity', 'High Acidity', 'Extra High Acidity'],
        tannin: ['Very Low Tannins', 'Low Tannins', 'Medium Tannins', 'High Tannins', 'Extra High Tannins']
      };
      return headings[characteristic][level - 1] || 'Unknown Characteristic';
    };
  
    return (
      <div className="characteristic-row" onClick={() => openCharacteristicModal(characteristic)}>
        <div className="characteristic-content">
          <div className="characteristic-chart">
            <CustomBarChart 
              data={chartData} 
              currentLevel={level} 
              colors={characteristicColors[characteristic]}
              characteristic={characteristic}
              menuName={menuName}
              menuData={menuData}
              addToBasket={addToBasket}
              removeFromBasket={removeFromBasket}
              basketItems={basketItems}
              currency={currency}
              openDescriptionModal={openDescriptionModal}
            />
          </div>
          <div className="characteristic-heading">
            {getCharacteristicHeading()}
            <ConfidenceIndicator confidence={currentItem.wine_info?.characteristics?.[characteristic].confidence} />
          </div>
        </div>
      </div>
    );
  };

  const isWineItem = currentItem.is_wine && currentItem.wine_info;

  const getPairedItems = (menuPairings) => {
    if (!menuPairings) return [];
    
    return menuData.flatMap(menu => 
      menu.courses.flatMap(course => 
        course.i.filter(item => menuPairings.includes(item.uid))
      )
    );
  };

  const renderPairings = () => {
    if (!winePairingsFetched) {
      return (
        <div className="wine-pairings">
          <h4 className="wine-info-heading">RECOMMENDED PAIRINGS</h4>
        <div className="wine-pairings-loading">
          <div className="loading-placeholder"></div>
        </div>
        </div>
      );
    }

    const menuPairings = currentItem.wine_info?.menu_pairings;
    
    if (!menuPairings) {
      <div className="wine-pairings">
          <h4 className="wine-info-heading">RECOMMENDED PAIRINGS</h4>
          <p> Go for anything! </p>    
      </div>
    }

    const pairedItems = getPairedItems(menuPairings);

    return (
      <div className="wine-pairings">
        <h4 className="wine-info-heading">RECOMMENDED PAIRINGS</h4>
        <div className="paired-items-list">
          {pairedItems.length === 0 &&
            <span className="no-parirings-found">
              {/*Nothing in particular.*/}
            </span>
          }
          {pairedItems.map((item, index) => (
            <MenuItemCard
              key={index}
              item={item}
              addToBasket={addToBasket}
              removeFromBasket={removeFromBasket}
              basketItems={basketItems}
              currency={currency}
              itemIndex={index}
              courseName=""
              openDescriptionModal={() => openDescriptionModal(item)}
              isWineInfoPending={false}
              menuData={menuData}
              disableDescriptionModal={true}
            />
          ))}
        </div>
      </div>
    );
  };

  if (!isWineItem) {
    return (
        <ItemDescriptionModal
            item={currentItem}
            closeModal={closeModal}
            addToBasket={addToBasket}
            removeFromBasket={removeFromBasket}
            basketItems={basketItems}
            currency={currency}
            courseName={courseName}
            getNextItem={getNextItem}
            getPreviousItem={getPreviousItem}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            menuData={menuData}
        />
    );
    }

    return (
        <div className={`wine-description-modal-overlay ${isClosing ? 'closing' : ''}`} onClick={handleClose}>
          <div 
            ref={modalRef}
            className={`wine-description-modal-content ${isClosing ? 'closing' : ''} ${swipeDirection ? `swipe-${swipeDirection}` : ''}`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
        <button className="wine-description-close-button" onClick={handleClose}>×</button>        
        <div className="wine-description-modal-header">
          <h2 className="wine-description-name">{currentItem.e || 'Unknown'}</h2>
          <p className="wine-description-price">
            {validVariantEntries.length > 1 
              ? `From ${currency}${getPrice()?.toFixed(2) || 'N/A'}` 
              : `${currency}${getPrice()?.toFixed(2) || 'N/A'}`
            }
          </p>
          {getNextItem && getPreviousItem && (
            <div className={`swipe-indicator ${isInitialRender ? 'initial' : 'subsequent'} ${isSwipeIndicatorVisible ? 'visible' : ''}`}>
              <div className="swipe-graphic">
                <span className="swipe-arrow">←</span>
                <div className="swipe-line"></div>
                <span className="swipe-arrow">→</span>
              </div>
              <p className="swipe-text">Swipe to view next</p>
            </div>
          )}
        </div>

        <div className="wine-description-modal-scrollable-content">
          <div className="wine-info-card">
            <div className="wine-info-grid">
              {renderWineInfo('VARIETY', currentItem.wine_info?.grape_varietals.varieties?.join(', '), currentItem.wine_info?.grape_varietals.confidence)}
              {renderWineInfo('ORIGIN', currentItem.wine_info?.origin?.region, currentItem.wine_info?.origin?.confidence)}
              {renderWineInfo('STYLE', 
                currentItem.wine_info?.style
                  ? <>
                      {currentItem.wine_info.style.category || ''}
                      {currentItem.wine_info.style.specific && <><br />{currentItem.wine_info.style.specific}</>}
                    </>
                  : 'N/A',
                currentItem.wine_info?.style?.confidence
              )}
              <div className="wine-info-column">
                <h4 className="wine-info-heading">
                  APPEARANCE
                  <ConfidenceIndicator confidence={currentItem.wine_info?.appearance?.confidence} />
                </h4>
                <div className="wine-appearance">
                  <div 
                    className="wine-color-box" 
                    style={{backgroundColor: currentItem.wine_info?.appearance?.color?.hue || 'transparent'}}
                  />
                  <p className="wine-info-text">
                    {currentItem.wine_info?.appearance?.clarity?.description || 'N/A'}
                  </p>
                </div>
              </div>
            </div>

            <div className="wine-info-row">
              <div className="wine-info-column full-width">
                <h4 className="wine-info-heading">DESCRIPTION</h4>
                <p className="wine-info-text">{currentItem.a || 'No description available.'}</p>
                <h4 className="wine-info-heading">TYPICAL PAIRINGS <ConfidenceIndicator confidence={currentItem.wine_info?.typical_pairings.confidence} /></h4>
                <p className="wine-info-text">{currentItem.wine_info?.typical_pairings.typical_pairings || 'Not available.'}</p>
              </div>
            </div>

            <div className="wine-info-row">
              <div className="wine-info-column full-width">
                <h4 className="wine-info-heading">CHARACTERISTICS</h4>
                {renderCharacteristic('body')}
                {renderCharacteristic('sweetness')}
                {renderCharacteristic('acidity')}
                {renderCharacteristic('tannin')}
              </div>
            </div>
          </div>

          <button className="wine-description-add-button" onClick={handleAddToOrder}>
            Add to order
          </button>
          {renderPairings()}
        </div>
      </div>

      {showPriceSelectionModal && (
        <PriceSelectionModal
          item={item}
          closeModal={() => setShowPriceSelectionModal(false)}
          addToBasket={addToBasket}
          removeFromBasket={removeFromBasket}
          basketItems={basketItems}
          currency={currency}
        />
    
      )}
    </div>
  );
};

export default WineDescriptionModal;