import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import packageInfo from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        updateViaCache: 'none'
      });
      
      // Check for updates immediately and every minute
      const checkForUpdates = () => {
        registration.update().then(() => {
          if (registration.waiting) {
            // New version available, force update
            registration.waiting.postMessage({ 
              type: 'SKIP_WAITING',
              version: packageInfo.version 
            });
          }
        });
      };
      
      checkForUpdates();
      setInterval(checkForUpdates, 60 * 1000);

      // Force reload when new version is activated
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log(`Updating to version ${packageInfo.version}`);
        window.location.reload();
      });

      console.log('SW registered: ', registration);
    } catch (error) {
      console.error('Error registering service worker:', error);
    }
  }
};

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('load', registerServiceWorker);
} else {
  console.log('Service worker not registered in development mode.');
}

reportWebVitals();