import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/ItemDescriptionModal.css';
import PriceSelectionModal from './PriceSelectionModal';
import WineDescriptionModal from './WineDescriptionModal';

const ItemDescriptionModal = ({ 
  item, 
  closeModal, 
  addToBasket, 
  removeFromBasket,
  basketItems,
  currency, 
  courseName,
  getNextItem,
  getPreviousItem,
  isFirstItem,
  isLastItem,
  menuData,
  openDescriptionModal,
  winePairingsFetched,
  menuName,
}) => {

  const [isClosing, setIsClosing] = useState(false);
  const [currentItem, setCurrentItem] = useState(item);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [showPriceSelectionModal, setShowPriceSelectionModal] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const modalRef = useRef(null);
  const [isSwiping, setIsSwiping] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isSwipeIndicatorVisible, setIsSwipeIndicatorVisible] = useState(false);

  const validVariants = Object.entries(currentItem.p).reduce((acc, [key, value]) => {
    if (value && value.name !== "" && value.price > 0) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const validVariantEntries = Object.entries(validVariants);
  const singleValidVariant = validVariantEntries.length === 1 ? validVariantEntries[0][1] : null;

  const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      window.history.back();
    }, 300);
  }, [closeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSwipeIndicatorVisible(true);
    }, 500); // Delay of 500ms before showing the swipe indicator
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    const handlePopState = (event) => {
      event.preventDefault();
      handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    window.addEventListener('popstate', handlePopState);

    // Push a new state to the history when opening the modal
    window.history.pushState(null, '');

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', handleEscape);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleClose]);

  const getPrice = () => {
    if (typeof currentItem.p === 'number') {
      return currentItem.p;
    } else if (singleValidVariant) {
      return singleValidVariant.price;
    } else {
      const validPrices = Object.values(validVariants).map(variant => variant.price);
      return validPrices.length > 0 ? Math.min(...validPrices) : null;
    }
  };

  const handleAddToOrder = () => {
    if (typeof currentItem.p === 'number' || singleValidVariant) {
      const itemId = `${currentItem.e}${singleValidVariant ? ` - ${singleValidVariant.name}` : ''}`;
      const price = typeof currentItem.p === 'number' ? currentItem.p : singleValidVariant.price;
      addToBasket(itemId, {
        id: itemId,
        name: currentItem.e,
        originalName: currentItem.o,
        price: price,
        variant: singleValidVariant ? singleValidVariant.name : '',
      });
      closeModal();
    } else {
      setShowPriceSelectionModal(true);
    }
  };

  const handleSwipe = (direction) => {
    if (!getNextItem || !getPreviousItem) return;
    
    
    const newItem = direction === 'left' ? getNextItem(currentItem) : getPreviousItem(currentItem);
  
    if (newItem && ((direction === 'left' && !isLastItem) || (direction === 'right' && !isFirstItem))) {
      setSwipeDirection(direction);
      setTimeout(() => {
        setCurrentItem(newItem);
        setSwipeDirection(null);
      }, 300);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setIsSwiping(false);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchEnd = () => {
    if (!isSwiping) return;
  
    const swipeThreshold = 100;
    const swipeDistance = touchEndX - touchStartX;
  
    //console.log('Touch end:', { swipeDistance, touchStartX, touchEndX });
  
    if (Math.abs(swipeDistance) > swipeThreshold) {
      if (swipeDistance > 0) {
        handleSwipe('right');
      } else {
        handleSwipe('left');
      }
    }
  
    // Reset touch coordinates and swiping state
    setTouchStartX(0);
    setTouchEndX(0);
    setIsSwiping(false);
  };

  // Check if the current item is a wine
  if (currentItem.is_wine && currentItem.wine_info) {
    return (
      <WineDescriptionModal
        item={currentItem}
        closeModal={closeModal}
        addToBasket={addToBasket}
        removeFromBasket={removeFromBasket}
        basketItems={basketItems}
        currency={currency}
        courseName={courseName}
        getNextItem={getNextItem}
        getPreviousItem={getPreviousItem}
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        menuData={menuData}
        openDescriptionModal={openDescriptionModal}
        winePairingsFetched={winePairingsFetched}
        menuName={menuName}
      />
    );
  }

  return (
    <div 
      className={`item-description-modal-overlay ${isClosing ? 'closing' : ''}`} 
      onClick={handleClose}
    >
      <div 
        ref={modalRef}
        className={`item-description-modal-content ${isClosing ? 'closing' : ''} ${swipeDirection ? `swipe-${swipeDirection}` : ''}`}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button className="item-description-close-button" onClick={handleClose}>×</button>
        {getNextItem && getPreviousItem && (
          <div className={`swipe-indicator ${isInitialRender ? 'initial' : 'subsequent'} ${isSwipeIndicatorVisible ? 'visible' : ''}`}>
            <div className="swipe-graphic">
              <span className="swipe-arrow">←</span>
              <div className="swipe-line"></div>
              <span className="swipe-arrow">→</span>
            </div>
            <p className="swipe-text">Swipe to view next</p>
          </div>
        )}
        <div className="item-description-header">
          <h2 className="item-description-name">{currentItem.e}</h2>
          <h3 className="item-description-original-name">{currentItem.o}</h3>
          <p className="item-description-price">
            {validVariantEntries.length > 1 
              ? `From ${currency}${getPrice().toFixed(2)}` 
              : `${currency}${getPrice().toFixed(2)}`
            }
          </p>
          <p className="item-description-course">{courseName}</p>
        </div>
        {currentItem.a && currentItem.a.length > 0 && 
          <div className="item-description-body">
            <h4 className="item-description-title">DESCRIPTION</h4>
            <p className="item-description-text">{currentItem.d}</p>
            <p className="item-description-additional">{currentItem.a}</p>
          </div>
        }
        <button className="item-description-add-button" onClick={handleAddToOrder}>
          Add to order
        </button>
        </div>
      {showPriceSelectionModal && (
        <PriceSelectionModal
          item={currentItem}
          closeModal={() => setShowPriceSelectionModal(false)}
          addToBasket={addToBasket}
          removeFromBasket={removeFromBasket}
          basketItems={basketItems}
          currency={currency}
        />
      )}
    </div>
  );
};

export default ItemDescriptionModal;