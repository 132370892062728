import axios from 'axios';
import { auth } from './firebase';
import imageCompression from 'browser-image-compression';

const BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:5000' 
  : 'https://menu-vision-server-225c505ea087.herokuapp.com';


export const submitMenuQuery = async (menuData, query) => {
  const response = await axios.post(`${BASE_URL}/api/submitQuery`, {
    menuData: menuData,
    query: query,
  });
  return response.data;
};



export const getWineMenu = async (wineOnlyData) => {
  const response = await axios.post(`${BASE_URL}/api/getWineMenu`, {
    menuData: wineOnlyData,
  });
  return response.data;
};


export const compressImage = async (file) => {
  const options = {
    maxSizeMB: 0.5, // Max file size of 500KB
    maxWidthOrHeight: 1920, // Limit width/height to 1920px
    useWebWorker: true,
    fileType: 'image/jpeg', // Convert all images to JPEG for consistency
  };

  try {
    const compressedFile = await imageCompression(file, options);
    // If the compressed file is smaller than 300KB, we'll use the original file
    if (compressedFile.size < 300 * 1024) {
      return file;
    }
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return file; // Return original file if compression fails
  }
};

export const saveMenuToDatabase = async (restaurantUid, name, streetAddress, menuData, imageFiles, uploadedBy) => {
  const formData = new FormData();
  formData.append('restaurantUid', restaurantUid);
  formData.append('name', name);
  formData.append('streetAddress', streetAddress);
  formData.append('menuData', JSON.stringify(menuData));
  formData.append('scannedAt', new Date().toISOString());
    formData.append('uploadedBy', uploadedBy);

  const headers = await getAuthHeaders();
  headers['Content-Type'] = 'multipart/form-data';

  // Attach the images
  if (imageFiles && imageFiles.length > 0) {
    imageFiles.forEach((file, index) => {
      formData.append('images', file);
    });
  }

  const response = await axios.post(`${BASE_URL}/api/menu/saveMenu`, formData, { headers });
  return response.data;
};

export const getMenuById = async (id) => {
  const response = await axios.get(`${BASE_URL}/api/menu/getMenu/${id}`);
  return response.data;
};

export const getMenusByRestaurant = async (restaurantId) => {
  const response = await axios.get(`${BASE_URL}/api/menu/getMenusByRestaurant/${restaurantId}`);
  return response.data;
};

export const getMenusByUser = async () => {
  const headers = await getAuthHeaders();
  const response = await axios.get(`${BASE_URL}/api/menu/getMenusByUser`, { headers });
  return response.data;
};

export const getWinePairings = async (menuData) => {
  const response = await axios.post(`${BASE_URL}/api/getWinePairings`, {menuData: menuData});
  return response.data;
};

export const saveOrderToDatabase = async (name, menuData) => {
  return "hi!";
};

const getAuthHeaders = async () => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
};



export const uploadMenuImage = async (file, onChunk, onDone, onError) => {
  if (!file) return;

  const formData = new FormData();
  formData.append('image', file);
  console.log("Receiving file", file);

  try {
    const response = await fetch(`${BASE_URL}/api/getBaseMenu`, {
      method: 'POST',
      body: formData,
      headers: await getAuthHeaders(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (line) {
          try {
            const eventData = JSON.parse(line);
            switch (eventData.type) {
              case 'chunk':
                onChunk(eventData.data);
                break;
              case 'done':
                onDone();
                return;
              case 'refusal':
              case 'refused':
                onError('Request refused by the server');
                return;
              case 'error':
                onError(eventData.message);
                return;
              default:
                console.error(`Unexpected event type: ${eventData.type}`);
            }
          } catch (parseError) {
            console.error('Error parsing event data:', parseError);
          }
        }
      }
    }

    // Process any remaining data in the buffer
    if (buffer.trim()) {
      const lines = buffer.split('\n');
      for (const line of lines) {
        if (line) {
          try {
            const eventData = JSON.parse(line);
            switch (eventData.type) {
              case 'chunk':
                onChunk(eventData.data);
                break;
              case 'done':
                onDone();
                return;
              case 'refusal':
              case 'refused':
                onError('Request refused by the server');
                return;
              case 'error':
                onError(eventData.message);
                return;
              default:
                console.error(`Unexpected event type: ${eventData.type}`);
            }
          } catch (parseError) {
            console.error('Error parsing event data:', parseError);
          }
        }
      }
    } else {
      // If buffer is empty, ensure onDone is called
      onDone();
    }
  } catch (error) {
    onError(error.message);
  }
};



export const getUserProfile = async () => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.get(`${BASE_URL}/api/user/profile`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (profileUpdates) => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.put(
      `${BASE_URL}/api/user/profile`, 
      profileUpdates,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const incrementUserPoints = async () => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.post(
      `${BASE_URL}/api/user/increment-points`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error incrementing user points:', error);
    //throw error;
  }
};

export const deleteUserAccount = async () => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.delete(`${BASE_URL}/api/user/delete-account`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error deleting user account:', error);
    throw error;
  }
};

export const fetchNearbyVenues = async (latitude, longitude, radius, maxResults) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/venues/nearby`, {
      params: { latitude, longitude, radius, maxResults }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching nearby venues:', error);
    throw error;
  }
};

export const fetchVenueDetails = async (venueId) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/venues/details/${venueId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching venue details:', error);
    throw error;
  }
};

export const getAllRestaurants = async () => {
  const response = await axios.get(`${BASE_URL}/api/restaurants/getAllRestaurants`);
  return response.data;
};