// utils/basketUtils.js

export const addToBasket = (basketItems, itemId, itemData) => {
    const existingItemIndex = basketItems.findIndex(
      (basketItem) => basketItem.id === itemId
    );
  
    if (existingItemIndex !== -1) {
      return basketItems.map((item, index) =>
        index === existingItemIndex
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      return [...basketItems, { ...itemData, quantity: 1 }];
    }
  };
  
  export const removeFromBasket = (basketItems, itemId) => {
    const existingItemIndex = basketItems.findIndex(
      (basketItem) => basketItem.id === itemId
    );
  
    if (existingItemIndex !== -1) {
      const updatedBasket = [...basketItems];
      if (updatedBasket[existingItemIndex].quantity > 1) {
        updatedBasket[existingItemIndex] = {
          ...updatedBasket[existingItemIndex],
          quantity: updatedBasket[existingItemIndex].quantity - 1,
        };
      } else {
        updatedBasket.splice(existingItemIndex, 1);
      }
      return updatedBasket;
    }
    return basketItems;
  };
  
  export const updateBasketItemQuantity = (basketItems, itemId, quantity) => {
    return basketItems
      .map((item) => (item.id === itemId ? { ...item, quantity } : item))
      .filter((item) => item.quantity > 0);
  };
  
  export const getBasketItemCount = (basketItems) => {
    return basketItems.reduce((total, item) => total + item.quantity, 0);
  };