// src/components/CharacteristicModal.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import MenuItemCard from './MenuItemCard';
import '../styles/CharacteristicModal.css';

const CharacteristicModal = ({ 
  characteristic, 
  level, 
  menuName, 
  data, 
  colors, 
  onClose, 
  menuData,
  addToBasket,
  removeFromBasket,
  basketItems,
  currency,
  openDescriptionModal
}) => {
  const [currentLevel, setCurrentLevel] = useState(level);

  const characteristicLabels = {
    body: ['Very Light Body', 'Light Body', 'Medium Body', 'Full Body', 'Extra Full Body'],
    sweetness: ['Bone Dry', 'Dry', 'Off-Dry', 'Sweet', 'Very Sweet'],
    acidity: ['Very Low Acidity', 'Low Acidity', 'Medium Acidity', 'High Acidity', 'Extra High Acidity'],
    tannin: ['Very Low Tannin', 'Low Tannin', 'Medium Tannin', 'High Tannin', 'Extra High Tannin']
  };

  const characteristicLabel = characteristicLabels[characteristic][currentLevel - 1];

  useEffect(() => {
    const handlePopState = () => {
      onClose();
    };

    window.addEventListener('popstate', handlePopState);
    window.history.pushState(null, '');

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose]);

  const filteredWines = menuData.flatMap(menu => 
    menu.courses.flatMap(course => 
      course.i.filter(wine => 
        wine.is_wine && 
        wine.wine_info?.characteristics?.[characteristic]?.level === currentLevel
      )
    )
  );

  const handleBarClick = (entry, index) => {
    setCurrentLevel(index + 1);
  };

  return ReactDOM.createPortal(
    <div className="characteristic-modal-overlay" onClick={onClose}>
      <div className="characteristic-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &#10005;
        </button>
        <h2 className="modal-heading">{`${characteristicLabel} WINES AT ${menuName}`}</h2>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={60}>
            <BarChart 
              data={data} 
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              barCategoryGap={0.1}
            >
              <Bar dataKey="value" radius={[4, 4, 0, 0]} onClick={handleBarClick}>
                {data.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={colors[index]}
                    stroke={index + 1 === currentLevel ? 'black' : colors[index]}
                    strokeWidth={index + 1 === currentLevel ? 2 : 0}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="wine-list">
          {filteredWines.map((wine, index) => (
            <MenuItemCard
              key={index}
              item={wine}
              addToBasket={addToBasket}
              removeFromBasket={removeFromBasket}
              basketItems={basketItems}
              currency={currency}
              itemIndex={index}
              courseName=""
              openDescriptionModal={() => openDescriptionModal(wine)}
              isWineInfoPending={false}
              menuData={menuData}
              disableDescriptionModal={true}
            />
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CharacteristicModal;