import React, { useState, useMemo } from 'react';
import '../styles/MenuItemCard.css';
import PriceSelectionModal from './PriceSelectionModal';

// Import all images
import sweetnessDry from '../images/sweetness-very-dry.png';
import sweetnessMediumDry from '../images/sweetness-dry.png';
import sweetnessMedium from '../images/sweetness-medium.png';
import sweetnessSweet from '../images/sweetness-sweet.png';
import sweetnessVerySweet from '../images/sweetness-extra-sweet.png';

import bodyLight from '../images/body-light.png';
import bodyMedium from '../images/body-medium.png';
import bodyFull from '../images/body-full.png';
import bodyExtraFull from '../images/body-extra-full.png';

import allergenWarningIcon from '../images/warning.png';

import unknownCountryFlag from "../images/unknown-country-flag.png";

const getSweetnessIcon = (level) => {
  const icons = [sweetnessDry, sweetnessMediumDry, sweetnessMedium, sweetnessSweet, sweetnessVerySweet];
  return icons[Math.min(Math.max(0, Math.floor(level) - 1), 4)];
};

const getBodyIcon = (level) => {
  const icons = [bodyLight, bodyLight, bodyMedium, bodyFull, bodyExtraFull];
  return icons[Math.min(Math.max(0, Math.floor(level) - 1), 4)];
};

const getSweetnessText = (level) => {
  const texts = [
    'V. Dry',
    'Dry',
    'Off-Dry',
    'Sweet',
    'V. Sweet'
  ];
  return texts[Math.min(Math.max(0, Math.floor(level) - 1), 4)];
};

const getBodyText = (level) => {
  const texts = [
    'Light',
    'Light-Med',
    'Medium',
    'Med-Full',
    'Full'
  ];
  return texts[Math.min(Math.max(0, Math.floor(level) - 1), 4)];
};

function MenuItemCard({ 
  item, 
  addToBasket, 
  removeFromBasket, 
  basketItems, 
  currency, 
  itemIndex,
  courseName,
  openDescriptionModal,
  isWineInfoPending,
  menuData,
  disableDescriptionModal,
  profile,
  setProfile,
  fadeIn = false,
  readOnly = false,
  useWhiteBackground = false, // New prop for background color
}) {
  const [showPriceSelectionModal, setShowPriceSelectionModal] = useState(false);
  const [showSweetnessTooltip, setShowSweetnessTooltip] = useState(false);
  const [showBodyTooltip, setShowBodyTooltip] = useState(false);
  const [showAllergenTooltip, setShowAllergenTooltip] = useState(false);

  const validVariants = useMemo(() => {
    if (!item || !item.p || typeof item.p !== 'object') {
      return {};
    }

    return Object.entries(item.p).reduce((acc, [key, value]) => {
      if (value && 
          typeof value === 'object' && 
          'name' in value && 
          'price' in value && 
          value.name !== "" && 
          typeof value.price === 'number' && 
          value.price > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [item]);


  const validVariantEntries = Object.entries(validVariants);
  const singleValidVariant = validVariantEntries.length === 1 ? validVariantEntries[0][1] : null;

  const generateItemId = (variantName = '') => {
    return `${item.e}${variantName ? ` - ${variantName}` : ''}`;
  };

  const getTotalQuantity = () => {
    return basketItems.reduce((total, basketItem) => {
      return basketItem.id.startsWith(item.e) ? total + basketItem.quantity : total;
    }, 0);
  };

  const getPrice = () => {
    if (typeof item.p === 'number') {
      return item.p;
    } else if (singleValidVariant) {
      return singleValidVariant.price;
    } else {
      const validPrices = Object.values(validVariants).map(variant => variant.price);
      return validPrices.length > 0 ? Math.min(...validPrices) : null;
    }
  };

  const handleCardClick = () => {
    if (!disableDescriptionModal) {
      openDescriptionModal(item);
    }
  };

  const handleItemAction = (action, e) => {
    e.stopPropagation(); // Prevent the description modal from opening
    if (typeof item.p === 'number' || singleValidVariant) {
      const itemId = generateItemId(singleValidVariant ? singleValidVariant.name : '');
      const price = typeof item.p === 'number' ? item.p : singleValidVariant.price;
      if (action === 'add') {
        addToBasket(itemId, {
          id: itemId,
          name: item.e,
          originalName: item.o,
          price: price,
          variant: singleValidVariant ? singleValidVariant.name : '',
        });
      } else {
        removeFromBasket(itemId);
      }
    } else {
      setShowPriceSelectionModal(true);
    }
  };

  const renderButtons = () => {
    const quantity = getTotalQuantity();
    return (
      <div className="basket-button-container" id={`button-container-${itemIndex}`}>
        <button className="plus-button" onClick={(e) => handleItemAction('add', e)}>+</button>
        {quantity > 0 && (
          <button className="minus-button" onClick={(e) => handleItemAction('remove', e)}>-</button>
        )}
      </div>
    );
  };

  const renderPriceInfo = () => {
    const quantity = getTotalQuantity();
    const price = getPrice();

    if (price !== null) {
      const priceDisplay = singleValidVariant ? (
        <span>{currency}{price.toFixed(2)} {singleValidVariant.name}</span>
      ) : (
        <span>{validVariantEntries.length > 1 ? 'From ' : ''}{currency}{price.toFixed(2)}</span>
      );

      return quantity === 0 ? priceDisplay : (
        <span>({quantity}) {priceDisplay}</span>
      );
    } else {
      return <span>Price not available</span>;
    }
  };

  const renderWineInfo = () => {
    if (!item.is_wine) return null;

    //console.log("Within render, isWineInfoPending = ", isWineInfoPending);
    if (item.is_wine && isWineInfoPending && !readOnly) {
      return (
        <div className="wine-info loading">
          <div className="wine-info-pill loading"></div>
          <div className="wine-info-pill loading"></div>
          <div className="wine-info-flag loading"></div>
        </div>
      );
    }

    const sweetness = item.wine_info?.characteristics?.sweetness;
    const body = item.wine_info?.characteristics?.body;
    const countryFlag = item.wine_info?.origin?.country;

    return (
      <div className="wine-info">
        <div 
          className="wine-info-pill"
          onMouseEnter={() => setShowSweetnessTooltip(true)}
          onMouseLeave={() => setShowSweetnessTooltip(false)}
          onClick={(e) => e.stopPropagation()}
        >
          <img 
            src={getSweetnessIcon(sweetness?.level)}
            alt=""
            className="wine-info-icon"
          />
          <span>{getSweetnessText(sweetness?.level)}</span>
          {showSweetnessTooltip && (
            <div className="wine-info-tooltip">
              Sweetness
            </div>
          )}
        </div>
        <div 
          className="wine-info-pill"
          onMouseEnter={() => setShowBodyTooltip(true)}
          onMouseLeave={() => setShowBodyTooltip(false)}
          onClick={(e) => e.stopPropagation()}
        >
          <img 
            src={getBodyIcon(body?.level)}
            alt=""
            className="wine-info-icon"
          />
          <span>{getBodyText(body?.level)}</span>
          {showBodyTooltip && (
            <div className="wine-info-tooltip">
              Body
            </div>
          )}
        </div>
        <div className="wine-info-flag">
          {countryFlag ? (
            <span>{countryFlag}</span>
          ) : (
            <img 
              src={unknownCountryFlag} 
              alt="Unknown country"
              className="unknown-country-flag"
            />
          )}
        </div>
      </div>
    );
  };

  const userAllergies = profile?.dietaryPreferences.allergies || [];
  const userSelectedAllergens = item.allergens?.filter(allergen => userAllergies.includes(allergen));

  return (
    <div className="menu-item-card" onClick={handleCardClick}>
      <div className={`menu-item-header ${fadeIn ? 'fade-in' : ''} ${useWhiteBackground ? 'white-background' : ''}`}>
        <div className={`item-info ${fadeIn ? 'fade-in' : ''}`}>
          <span className={`item-name ${fadeIn ? 'fade-in' : ''}`}>{item.e}</span>
          {!disableDescriptionModal && (
            <div className="additional-info">
              Tap to see description
            </div>
          )}

          <div className={`price-info ${fadeIn ? 'fade-in' : ''}`}>{renderPriceInfo()}</div>
          {item.is_wine && renderWineInfo()}
        </div>
        {/* Display the allergen warning icon only if allergens match user's selected allergies */}
        {userSelectedAllergens && userSelectedAllergens.length > 0 && (
          <div 
            className="allergen-warning"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowAllergenTooltip(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowAllergenTooltip(false);
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowAllergenTooltip(!showAllergenTooltip);
            }}
          >
            <img src={allergenWarningIcon} alt="Allergen Warning" className="allergen-icon" />
            {showAllergenTooltip && (
              <div className="allergen-tooltip">
                <p>EXPERIMENTAL FEATURE</p>
                <p> May contain {userSelectedAllergens.join(', ')}</p>
                <p>This information is indicative only, always check allergens with staff.</p>
              </div>
            )}
          </div>
        )}
        {renderButtons()}
      </div>
      {showPriceSelectionModal && validVariantEntries.length > 1 && (
        <PriceSelectionModal
          item={item}
          closeModal={() => setShowPriceSelectionModal(false)}
          addToBasket={addToBasket}
          removeFromBasket={removeFromBasket}
          basketItems={basketItems}
          currency={currency}
        />
      )}
    </div>
  );
}

export default MenuItemCard;