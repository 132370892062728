import React, { useState, useEffect, useCallback } from 'react';
import { updateUserProfile } from '../api';
import '../styles/PreferenceQuiz.css';

function PreferenceQuiz({ onClose, initialStep = 0, profile = {}, setProfile }) {
  const [step, setStep] = useState(initialStep);
  const [selectedOptions, setSelectedOptions] = useState({
    dietaryPreferences: profile.dietaryPreferences || {
      allergies: [],
      'gluten-free': false,
      pescatarian: false,
      vegan: false,
      vegetarian: false,
    },
    cuisines: profile.cuisines || [],
    diningInterests: profile.diningInterests || [],
    wineKnowledgeLevel: profile.wineKnowledgeLevel || "I don't care",
  });

  const handleBack = useCallback(() => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      onClose();
    }
  }, [step, onClose]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      handleBack();
    };

    return () => {
      window.onpopstate = null;
    };
  }, [handleBack]);

  // Define options
  const dietaryOptions = ['gluten-free', 'pescatarian', 'vegan', 'vegetarian'];
  const allergyOptions = [ "Milk",  "Eggs",  "Fish",  "Gluten",  "Molluscs",  "Shellfish",  "Tree Nuts",  "Peanuts",  "Soy",  "Celery",  "Sesame",  "Crustaceans",  "Sulphites", "Mustard"];
  const cuisineOptions = ['French', 'Italian', 'Chinese', 'Japanese', 'Mexican', 'Indian', 'Thai', 'Greek'];
  const diningInterestsOptions = ['Fine dining', 'Casual', 'Family-friendly', 'Fast food', 'Cafe', 'Buffet'];
  const wineKnowledgeLevels = ['Beginner', 'Intermediate', 'Expert', "I don't care"];

  const handleOptionToggle = (category, option) => {
    if (category === 'dietaryPreferences') {
      setSelectedOptions((prevState) => ({
        ...prevState,
        dietaryPreferences: {
          ...prevState.dietaryPreferences,
          [option]: !prevState.dietaryPreferences[option],
        },
      }));
    } else if (category === 'allergies') {
      const allergies = selectedOptions.dietaryPreferences.allergies;
      const updatedAllergies = allergies.includes(option)
        ? allergies.filter((a) => a !== option)
        : [...allergies, option];
      setSelectedOptions((prevState) => ({
        ...prevState,
        dietaryPreferences: {
          ...prevState.dietaryPreferences,
          allergies: updatedAllergies,
        },
      }));
    } else if (category === 'cuisines') {
      const updatedCuisines = selectedOptions.cuisines.includes(option)
        ? selectedOptions.cuisines.filter((c) => c !== option)
        : [...selectedOptions.cuisines, option];
      setSelectedOptions((prevState) => ({
        ...prevState,
        cuisines: updatedCuisines,
      }));
    } else if (category === 'diningInterests') {
      const updatedDiningInterests = selectedOptions.diningInterests.includes(option)
        ? selectedOptions.diningInterests.filter((d) => d !== option)
        : [...selectedOptions.diningInterests, option];
      setSelectedOptions((prevState) => ({
        ...prevState,
        diningInterests: updatedDiningInterests,
      }));
    } else if (category === 'wineKnowledgeLevel') {
      setSelectedOptions((prevState) => ({
        ...prevState,
        wineKnowledgeLevel: option,
      }));
    }
  };

  const handleNext = async () => {
    if (step < 3) {
      setStep(step + 1);
      window.history.pushState(null, '', window.window.location.href);
    } else {
      const updatedProfile = {
        ...profile,
        dietaryPreferences: selectedOptions.dietaryPreferences,
        cuisines: selectedOptions.cuisines,
        diningInterests: selectedOptions.diningInterests,
        wineKnowledgeLevel: selectedOptions.wineKnowledgeLevel,
      };
      setProfile(updatedProfile);
      try {
        await updateUserProfile(updatedProfile);
      } catch (error) {
        console.error('Error updating user profile:', error);
      }
      onClose(updatedProfile);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <div className="step-content">
            <span className="quiz-heading">Dietary Preferences</span>
            <div className="option-container">
              {dietaryOptions.map((option) => (
                <button
                  key={option}
                  className={`option-button ${selectedOptions.dietaryPreferences[option] ? 'selected' : ''}`}
                  onClick={() => handleOptionToggle('dietaryPreferences', option)}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1).replace('-', ' ')}
                </button>
              ))}
            </div>
            <span className="quiz-heading">Allergies</span>
            <div className="option-container">
              {allergyOptions.map((option) => (
                <button
                  key={option}
                  className={`option-button ${selectedOptions.dietaryPreferences.allergies.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionToggle('allergies', option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="step-content">
            <span className="quiz-heading">Cuisine Interests</span>
            <div className="option-container">
              {cuisineOptions.map((option) => (
                <button
                  key={option}
                  className={`option-button ${selectedOptions.cuisines.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionToggle('cuisines', option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <span className="quiz-heading">Dining Types</span>
            <div className="option-container">
              {diningInterestsOptions.map((option) => (
                <button
                  key={option}
                  className={`option-button ${selectedOptions.diningInterests.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionToggle('diningInterests', option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <span className="quiz-heading">Wine Knowledge</span>
            <div className="option-container">
              {wineKnowledgeLevels.map((option) => (
                <button
                  key={option}
                  className={`option-button ${selectedOptions.wineKnowledgeLevel === option ? 'selected' : ''}`}
                  onClick={() => handleOptionToggle('wineKnowledgeLevel', option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target.className === 'preference-quiz-overlay') {
      onClose();
    }
  };

  return (
    <div className="preference-quiz-overlay" onClick={handleOverlayClick}>
      <div className="preference-quiz-modal">
        <button className="back-button" onClick={handleBack}>&lt; BACK</button>
        {renderStepContent()}
        <button className="next-button" onClick={handleNext}>
          {step < 3 ? 'Continue' : 'Finish and close'}
        </button>
      </div>
    </div>
  );
}

export default PreferenceQuiz;