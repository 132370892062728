import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import '../styles/FileUploader.css';
import { uploadMenuImage, getWineMenu, getWinePairings, incrementUserPoints } from '../api';
import {
    compressFiles,
    addUIDs,
    extractWineItems,
    addWinesToMenu,
    addMenuPairingsToMenuData,
} from '../utils/fileUploadUtils';
import MessageModal from './MessageModal';
import { v4 as uuidv4 } from 'uuid';

const FileUploader = forwardRef((props, ref) => {
    const {
        uploadedImages,
        setUploadedImages,
        winePairingsFetched,
        setWinePairingsFetched,
        onMenuDataUpdate,
        onStepUpdate,
        setLoading,
        loading,
        setStep,
        step,
        setCurrency
    } = props;

    const fileInputRef = useRef(null);
    const [menuData, setMenuData] = useState([]);
    const [pairingsInProgress, setPairingsInProgress] = useState(false);
    const [warning, setWarning] = useState(''); // New state for warning message

    useEffect(() => {
        // Update parent component when step changes
        onStepUpdate(step);
    }, [step, onStepUpdate]);

    useEffect(() => {
        // Update parent component when menuData changes
        onMenuDataUpdate(menuData);
    }, [menuData, onMenuDataUpdate]);

    useEffect(() => {
        // Ensure menuData matches the order and length of uploadedImages
        setMenuData((prevMenuData) => {
            const updatedMenuData = uploadedImages.map((image) => {
                const existingMenu = prevMenuData.find(
                    (menu) => menu && menu.id === image.id
                );
                return existingMenu || '';
            });
            return updatedMenuData;
        });
    }, [uploadedImages]);

    // Monitor when all files have baseResolved and wineResolved set to true
    useEffect(() => {
        const allResolved = uploadedImages.every(
            (file) => file.baseResolved && (!file.wineNeeded || file.wineResolved)
        );
    
        const anyWineNeeded = uploadedImages.some(
            (file) => file.wineNeeded
        );
    
        if (allResolved && uploadedImages.length > 0 && !winePairingsFetched && !pairingsInProgress && anyWineNeeded) {
            setPairingsInProgress(true);
            const fetchWinePairings = async () => {
                try {
                    const pairings = await getWinePairings(menuData);
                    setWinePairingsFetched(true);
                    const updatedMenuData = addMenuPairingsToMenuData(menuData, pairings);
                    setMenuData(updatedMenuData);
                } catch (error) {
                    console.error('Error fetching wine pairings:', error);
                } finally {
                    setPairingsInProgress(false);
                }
            };
            fetchWinePairings();
        }
    }, [uploadedImages, menuData, winePairingsFetched, pairingsInProgress, setWinePairingsFetched]);
    

    const updateMenuWithUIDs = (currentMenuData, index) => {
        const updatedMenuData = [...currentMenuData];
        addUIDs(updatedMenuData[index], index);
        return updatedMenuData;
    };

    const processBaseMenuChunk = (file, chunk, imgIndex) => {
        // Once we start receiving chunks, set the state to home
        setStep('menu');
        setLoading(false);

        if (!chunk.uniqueKey) {
            chunk.uniqueKey = file.uniqueKey;
        }

        // Assign the id from the file to the chunk
        chunk.id = file.id;

        // Update the chunk at the correct index
        setMenuData((prevMenuData) => {
            const updatedMenuData = [...prevMenuData];
            updatedMenuData[imgIndex] = chunk;
            return updatedMenuData;
        });

        // Extract and set currency - for each chunk
        const newCurrency = chunk.currency || '£';
        setCurrency(newCurrency);
    };

    const finaliseBaseMenuData = async (file, index) => {
        if (uploadedImages[index]?.baseResolved) {
            // Already finalised
            return;
        }
        //console.log('Finalising menu');

        // Update uploadedImages state to set 'baseLoading: false' and 'baseResolved: true' for the file
        setUploadedImages((prevUploadedImages) => {
            const updatedUploadedImages = [...prevUploadedImages];
            updatedUploadedImages[index] = {
                ...updatedUploadedImages[index],
                baseLoading: false,
                baseResolved: true,
            };
            return updatedUploadedImages;
        });

        setMenuData((currentMenuData) => {
            let updatedMenuData = updateMenuWithUIDs(currentMenuData, index);

            // Extract wines
            const wineOnlyMenu = extractWineItems(updatedMenuData[index]);

            if (wineOnlyMenu.courses?.length > 0) {
                // We have wines
                if (uploadedImages[index]?.wineLoading || uploadedImages[index]?.wineResolved) {
                    // Already processing or processed
                    return updatedMenuData;
                }

                // We have wines, set 'wineNeeded: true' and 'wineLoading: true' for the file
                setUploadedImages((prevUploadedImages) => {
                    const updatedUploadedImages = [...prevUploadedImages];
                    updatedUploadedImages[index] = {
                        ...updatedUploadedImages[index],
                        wineNeeded: true,
                        wineLoading: true,
                    };
                    return updatedUploadedImages;
                });

                // Fetch the wine menu asynchronously
                getWineMenu(wineOnlyMenu)
                    .then((wineMenuData) => {
                        //console.log('Got wines!');
                        // Update 'wineLoading: false' and 'wineResolved: true' for the file
                        setUploadedImages((prevUploadedImages) => {
                            const updatedUploadedImages = [...prevUploadedImages];
                            updatedUploadedImages[index] = {
                                ...updatedUploadedImages[index],
                                wineLoading: false,
                                wineResolved: true,
                            };
                            return updatedUploadedImages;
                        });

                        // Update menuData with the wines
                        setMenuData((currentMenuData) => {
                            const combinedMenu = addWinesToMenu(
                                currentMenuData,
                                wineMenuData,
                                index
                            );
                            return combinedMenu;
                        });
                    })
                    .catch((error) => {
                        console.error('Error fetching wine menu:', error);
                        // Update 'wineLoading: false', 'wineResolved: true', and set an error flag
                        setUploadedImages((prevUploadedImages) => {
                            const updatedUploadedImages = [...prevUploadedImages];
                            updatedUploadedImages[index] = {
                                ...updatedUploadedImages[index],
                                wineLoading: false,
                                wineResolved: true,
                                wineError: true,
                            };
                            return updatedUploadedImages;
                        });
                    });
            } else {
                // No wines, set 'wineNeeded: false' and 'wineResolved: true' for the file
                setUploadedImages((prevUploadedImages) => {
                    const updatedUploadedImages = [...prevUploadedImages];
                    updatedUploadedImages[index] = {
                        ...updatedUploadedImages[index],
                        wineNeeded: false,
                        wineResolved: true,
                    };
                    return updatedUploadedImages;
                });
            }

            // Increment user points
            incrementUserPoints();

            // Return updatedMenuData
            return updatedMenuData;
        });
    };

    const handleBaseMenuUploadError = (file, error) => {
        const index = file.index;
        console.log(`Failed for file ${index}!`, error);

        setUploadedImages((prevUploadedImages) => {
            const updatedUploadedImages = [...prevUploadedImages];
            updatedUploadedImages[index] = {
                ...updatedUploadedImages[index],
                error: true,
                baseLoading: false,
            };
            return updatedUploadedImages;
        });

        setWarning(`Failed to upload file ${index}: ${error.message || 'An error occurred'}`);
        setStep('home'); // Go to home
        setLoading(false);
    };


    const handleInitialUpload = async (event) => {
        setLoading(true);
        setWinePairingsFetched(false); // Reset when new images are uploaded
        const files = Array.from(event.target.files);

        const compressedFiles = await compressFiles(files);
        // Get the current length of uploadedImages
        const currentUploadedImagesLength = uploadedImages.length;

        const newUploadedImagesData = compressedFiles.map((file, idx) => ({
            id: uuidv4(),
            file,
            url: URL.createObjectURL(file),
            baseNeeded: true,
            baseLoading: false,
            baseResolved: false,
            wineNeeded: false,
            wineLoading: false,
            wineResolved: false,
            error: false,
            index: currentUploadedImagesLength + idx, // Assign index properly
        }));

        // Append new images to existing uploadedImages
        setUploadedImages((prevUploadedImages) => [...prevUploadedImages, ...newUploadedImagesData]);

        // Initialize menuData with empty elements for new images
        setMenuData((prevMenuData) => [
            ...prevMenuData,
            ...Array.from({ length: newUploadedImagesData.length }, () => ''),
        ]);

        // Collect promises for base menu requests
        const baseMenuPromises = [];

        for (let i = 0; i < newUploadedImagesData.length; i++) {
            const file = newUploadedImagesData[i];
            const index = file.index; // index assigned earlier
            if (file.baseNeeded) {
                // Update uploadedImages state
                setUploadedImages((prevUploadedImages) => {
                    const updatedUploadedImages = [...prevUploadedImages];
                    updatedUploadedImages[index] = {
                        ...updatedUploadedImages[index],
                        baseLoading: true,
                    };
                    return updatedUploadedImages;
                });

                // Fetch the base menus
                const promise = uploadMenuImage(
                    file.file,
                    (chunk) => processBaseMenuChunk(file, chunk, index),
                    () => finaliseBaseMenuData(file, index),
                    (error) => handleBaseMenuUploadError(file, error)
                ).catch((error) => handleBaseMenuUploadError(file, error));
                baseMenuPromises.push(promise);
            }
        }

        // Wait for all base menu requests to complete
        await Promise.all(baseMenuPromises);
        //setLoading(false); // Loading is false when all base menu requests are done
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useImperativeHandle(ref, () => ({
        openFileDialog,
    }));

    return (
        <div>
            {loading && <p>Loading...</p>}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                multiple
                onChange={handleInitialUpload}
            />
            {/* Show the MessageModal if there is a warning message */}
        
            {warning && (
                <MessageModal
                    title="Warning"
                    message={warning}
                    onClose={() => setWarning('')} // Clear the warning on close
                />
            )}
        </div>
    );
});

export default FileUploader;
