import React, { useState, useEffect, useRef } from 'react';
import '../styles/HomePage.css';
import cutleryIcon from '../images/icon.png';
import AuthModal from './AuthModal';
import ProfileModal from './ProfileModal';
import MessageModal from './MessageModal';
import { auth } from '../firebase';
import packageInfo from '../../package.json';
import AdvicePopup from './AdvicePopup';
function HomePage({ handleScanMenu, user, setUser, profile, setProfile, setStep, step }) {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const [logoutMessage, setLogoutMessage] = useState('');
  
  const discoverButtonRef = useRef(null);
  const [tutorialStep, setTutorialStep] = useState(-1);
  const scanButtonRef = useRef(null);

  const tutorialSteps = [
    {
      text: "Welcome to Menu Vous! Let's get started with a quick tour.",
      targetRef: null,
    },
    {
      text: "Click the Discover button to see examples of what Menu Vous can do.",
      targetRef: discoverButtonRef,
    },
    {
      text: "Use the Scan Menu button to start translating and understanding menus.",
      targetRef: scanButtonRef,
    },
  ];

  const handlePreviousTutorialStep = () => {
    if (tutorialStep > 0) {
      setTutorialStep(tutorialStep - 1);
    }
  };

  const handleNextTutorialStep = () => {
    if (tutorialStep < tutorialSteps.length - 1) {
      setTutorialStep(tutorialStep + 1);
    } else {
      handleCloseTutorial();
    }
  };

  const handleCloseTutorial = () => {
    setTutorialStep(-1);
  };
  
  const handleFinishTutorial = () => {
    setTutorialStep(-1);
    localStorage.setItem('hasCompletedTutorial', 'true');
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    const hasCompletedTutorial = localStorage.getItem('hasCompletedTutorial');
    if (!hasCompletedTutorial) {
      setTutorialStep(0);
    }

    return () => unsubscribe();
  }, [setUser]);

  const handleAuthModalOpen = () => {
    setIsAuthModalOpen(true);
  };

  const handleAuthModalClose = () => {
    setIsAuthModalOpen(false);
  };

  const handleProfileModalOpen = () => {
    setIsProfileModalOpen(true);
  };

  const handleProfileModalClose = () => {
    setIsProfileModalOpen(false);
  };

  const handleButtonClick = () => {
    if (user) {
      handleProfileModalOpen();
    } else {
      handleAuthModalOpen();
    }
  };

  const goToDiscover = () => {
    setStep("discover");
  };

  return (
    <div className="home-page gingham-background">
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <img src={cutleryIcon} alt="Cutlery Icon" className="cutlery-icon" />
        <h1>MENU VOUS</h1>
        <h2>Dine without the guesswork.</h2>
        <div className="version-number">
          v.{packageInfo.version}
        </div>
        <div className="button-container">
        <button ref={scanButtonRef} className="primary-button" onClick={handleScanMenu}>
            Scan Menu
          </button>
          <button className="secondary-button" onClick={handleButtonClick}>
            {user ? (user.displayName || user.email) : 'Sign up / log in'}
          </button>
          <button ref={discoverButtonRef} className="secondary-button discover-button" onClick={goToDiscover}>
            Discover
          </button>
        </div>
        <footer className="homepage-footer" style={{ marginTop: 'auto' }}>
          <div className="homepage-footer-content">
            <p>TRANSLATE MENUS • ADD TO ORDER • GET WINE PAIRINGS • TRANSLATE ALLERGIES • EXPLAIN DISHES • GET TASTING NOTES • </p>
            <p>TRANSLATE MENUS • ADD TO ORDER • GET WINE PAIRINGS • TRANSLATE ALLERGIES • EXPLAIN DISHES • GET TASTING NOTES • </p>
          </div>
        </footer>
      </div>
      {isAuthModalOpen && (
        <AuthModal onClose={handleAuthModalClose} />
      )}
      {isProfileModalOpen && (
        <ProfileModal onClose={handleProfileModalClose} profile={profile} setProfile={setProfile}/>
      )}
      {logoutMessage && (
        <MessageModal title="SIGNED OUT" message={logoutMessage} onClose={() => setLogoutMessage('')} />
      )}
      {tutorialStep >= 0 && tutorialStep < tutorialSteps.length && (
        <AdvicePopup
          text={tutorialSteps[tutorialStep].text}
          onClose={handleCloseTutorial}
          onFinish={handleFinishTutorial}
          targetRef={tutorialSteps[tutorialStep].targetRef}
          index={tutorialStep}
          total={tutorialSteps.length}
          onPrevious={handlePreviousTutorialStep}
          onNext={handleNextTutorialStep}
        />
      )}
    </div>
  );
}

export default HomePage;
